import { Backdrop, CircularProgress } from "@material-ui/core";
import React from "react";
import styles from "../../styles";
import BusyIndicatorProps from "./BusyIndicatorProps";

export default function BusyIndicator(props: BusyIndicatorProps) {

    const classes = styles();

    return (
        <Backdrop className={classes.backdrop} open={props.busy}>
            <h2>{props.message}</h2>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
}