import { Box, Paper, Tab, Tabs, Typography } from '@material-ui/core';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import React, { useEffect, useState } from 'react';
import GenericTable from '../Shared/GenericTable';
import AdminService from './AdminService';

export default function AdminPage() {

    const [selectedTab, setSelectedTab] = useState('users');
    const handleTabChange = async (event: React.ChangeEvent<{}>, newValue: string) => {
        setSelectedTab(newValue);
    }

    const [selectedUser, setSelectedUser] = useState<CognitoIdentityServiceProvider.UserType | null>();

    const [users, setUsers] = useState<CognitoIdentityServiceProvider.UserType[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>(''); // Any error message from fetch / save

    useEffect(() => {
        const loadUsers = async () => {
            setIsLoading(true);
            try {
                const listUsers = await AdminService.listUsers();
                if (listUsers) {
                    setUsers(listUsers.map(u => {
                        return {
                            ...u,
                            email: getEmail(u)
                        };
                    }));
                }
            } catch (err) {
                console.error(err);
                setError('Error listing users');
            } finally {
                setIsLoading(false);
            }
        }

        loadUsers();
    }, []);

    const getEmail = (user: CognitoIdentityServiceProvider.UserType) => {
        return user.Attributes?.find(a => a.Name === 'email')?.Value || '';
    }

    return (
        <React.Fragment>
            <p style={{ color: "red" }} hidden={!error}>An error has occurred: {error}</p>
            <Box mb={1}>
                <Typography variant="h3">Admin Console</Typography>
            </Box>

            <Paper square>
                <Tabs
                    value={selectedTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleTabChange}
                    aria-label="disabled tabs example"
                >
                    <Tab value="users" label="Users" />
                </Tabs>
            </Paper>

            {selectedTab === 'users' &&
                <GenericTable<CognitoIdentityServiceProvider.UserType, {}>
                    title="Users"
                    columns={[
                        {
                            title: "Email",
                            field: "email",
                            render: u => getEmail(u),
                            defaultSort: "asc",
                            width: 100
                        },
                        {
                            title: "Enabled",
                            field: "Enabled",
                            render: o => o.Enabled === true ? 'true' : 'false',
                            width: 100
                        },
                        {
                            title: "Updated",
                            field: "UserLastModifiedDate",
                            width: 100
                        },
                        {
                            title: "Created",
                            field: "UserCreateDate",
                            width: 100
                        },
                    ]}
                    data={users}
                    isLoading={isLoading}
                    onRowClick={(_, rowData) => rowData && setSelectedUser(rowData)}
                    grouping={true}
                    optionOverrides={{
                        filtering: true
                    }}
                />
            }

        </React.Fragment>
    );
}
