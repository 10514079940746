import React, {  useState, useRef } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import FastRewindIcon from "@material-ui/icons/FastRewind";
import FastForwardIcon from "@material-ui/icons/FastForward";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import Popover from "@material-ui/core/Popover";
import ControlsProps from "./ControlsProps";



const useStyles = makeStyles((theme) => ({
    controlsWrapper: {
        visibility: "hidden",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        background: "rgba(0,0,0,0.6)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },

    button: {
        margin: theme.spacing(1),
    },
    controlIcons: {
        color: "#777",

        fontSize: 50,
        transform: "scale(0.9)",
        "&:hover": {
            color: "#fff",
            transform: "scale(1)",
        },
    },

    bottomIcons: {
        color: "#999",
        "&:hover": {
            color: "#fff",
        },
    },

    volumeSlider: {
        width: 100,
    },
}));

const PrettoSlider = withStyles({
    root: {
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: "#fff",
        border: "2px solid currentColor",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 8,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
})(Slider);

function ValueLabelComponent(props: { children: any; open: any; value: any; }) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}



const Controls = ( props: ControlsProps,ref:any) => {
        const classes = useStyles();
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleClick = (event:any) => {    
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        

        const open = Boolean(anchorEl);
        const id = open ? "simple-popover" : undefined;
        const onFastForward = () => {

        }

        return (
            <div  className={classes.controlsWrapper} ref={ref}>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    style={{ flexGrow: 1 }}
                >
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                        justify="space-between"
                        style={{ padding: 16 }}
                    >

                        <Grid item>

                        </Grid>
                    </Grid>
                    <Grid container direction="row" alignItems="center" justify="center">

                        <IconButton
                            onClick={props.onPlayPause}
                            className={classes.controlIcons}
                            aria-label="play"
                        >
                            {props.playing ? (
                                <PauseIcon fontSize="inherit" />
                            ) : (
                                <PlayArrowIcon fontSize="inherit" />
                            )}
                        </IconButton>
                        {/* <IconButton
                            onClick={onFastForward}
                            className={classes.controlIcons}
                            aria-label="forward"
                        >
                            <FastForwardIcon fontSize="inherit" />
                        </IconButton> */}
                    </Grid>
                    {/* bottom controls */}
                    <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        style={{ padding: 16 }}
                    >


                        <Grid item>
                            <Grid container alignItems="center">
  

                                {/* <IconButton
                                    onClick={props.onMute}
                                    className={classes.bottomIcons}
                                >
                                    {props.muted ? (
                                        <VolumeOff fontSize="large" />
                                    ) : (
                                        <VolumeDown fontSize="large" />
                                    )}
                                </IconButton> */}

                                <Slider
                                    min={0}
                                    max={100}
                                    // defaultValue={50}
                                    value={props.volume * 100}
                                    aria-labelledby="input-slider"
                                    className={classes.volumeSlider}
                                    onChange={props.onVolumeChange}
                                    onChangeCommitted={props.onVolumeSeekDown}
                                />
                                {/* <Button
                                    variant="text"
                                    onClick={
                                        onChangeDispayFormat
                                            () =>
                                          setTimeDisplayFormat(
                                            timeDisplayFormat == "normal" ? "remaining" : "normal"
                                          )
                                    }
                                >
                                    <Typography
                                        variant="body1"
                                        style={{ color: "#fff", marginLeft: 16 }}
                                    >
                                        {elapsedTime}/{totalDuration} 
                                    </Typography>
                                </Button> */}
                            </Grid>
                        </Grid>

                        <Grid item>
                            {/* <Button
                                onClick={handleClick}
                                aria-describedby={id}
                                className={classes.bottomIcons}
                                variant="text"
                            >
                                {<Typography>{playbackRate}X</Typography> }
                            </Button> */}

                            {/* <Popover
                                container={ref.current}
                                open={open}
                                id={id}
                                onClose={handleClose}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                transformOrigin={{
                                    vertical: "bottom",
                                    horizontal: "left",
                                }}
                            >
                                 <Grid container direction="column-reverse">
                                    {[0.5, 1, 1.5, 2].map((rate) => (
                                        <Button
                                            key={rate}
                                            //   onClick={() => setState({ ...state, playbackRate: rate })}
                                            onClick={() => onPlaybackRateChange(rate)}
                                            variant="text"
                                        >
                                            <Typography
                                                color={rate === playbackRate ? "secondary" : "inherit"}
                                            >
                                                {rate}X
                                            </Typography>
                                        </Button>
                                    ))}
                                </Grid> 
                            </Popover> */}
                            <IconButton
                                onClick={props.onToggleFullScreen}
                                className={classes.bottomIcons}
                            >
                                <FullScreen fontSize="large" />
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    };


// Controls.propTypes = {
//     onSeek: PropTypes.func,
//     onSeekMouseDown: PropTypes.func,
//     onSeekMouseUp: PropTypes.func,
//     onDuration: PropTypes.func,
//     onRewind: PropTypes.func,
//     onPlayPause: PropTypes.func,
//     onFastForward: PropTypes.func,
//     onVolumeSeekDown: PropTypes.func,
//     onChangeDispayFormat: PropTypes.func,
//     onPlaybackRateChange: PropTypes.func,
//     onToggleFullScreen: PropTypes.func,
//     onMute: PropTypes.func,
//     playing: PropTypes.bool,
//     played: PropTypes.number,
//     elapsedTime: PropTypes.string,
//     totalDuration: PropTypes.string,
//     muted: PropTypes.bool,
//     playbackRate: PropTypes.number,
// };

export default React.forwardRef(Controls);