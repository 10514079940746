import { Button, Chip, Grid, makeStyles, TextField, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TagContainerProps from './TagContainerProps';

const useStyles = makeStyles((theme: Theme) => ({
    tagContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    tag: {
        margin: theme.spacing(0.5),
    },
}));

export default function TagContainer(props: TagContainerProps) {

    const classes = useStyles();

    const [tags, setTags] = useState<string[]>([]);
    
    //const [tags, setTags] = useState<unknown>([]);

    const [currentTag, setCurrentTag] = useState<string>('');
    const handleCurrentTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentTag(e.target.value);
    }

    useEffect(() => {
       
        props.onChange(tags); 
        
    }, [tags]);  

    useEffect(() => {
        if (props.edit) {
          if (props.existingTags) {
          setTags(props.existingTags);
          }
      }
    
      }, []);

    const handleAdd = () => {
        if (currentTag) {
            setTags(tags => [...tags, currentTag]);
            setCurrentTag(''); 
        }
    }


    const handleDelete = (tagToDelete: string) => () => {
        setTags(tags.filter(tag => tag !== tagToDelete));
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={8} className={classes.tagContainer}>
                    
                    {Array.from(tags).map((tag) => {
                        return (
                            <li key={tag}>
                                <Chip
                                    label={tag}
                                    onDelete={handleDelete(tag)}
                                    className={classes.tag}
                                />
                            </li>
                        );
                    })}
                </Grid>
                <Grid xs={2} alignItems="flex-end">
                    <TextField
                        fullWidth
                        size="small"
                        value={currentTag}
                        onChange={handleCurrentTagChange} />
                </Grid>
                <Grid xs={2} alignItems="flex-end" style={{ direction: 'rtl' }}>
                    <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={handleAdd}>Add</Button>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}