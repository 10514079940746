import { Button, Grid, makeStyles, TextField, Theme, Typography, withWidth, WithWidthProps, MenuItem } from '@material-ui/core';
import React, { useEffect} from 'react';
import TagContainer from './TagContainer';
import UploadInputFieldProps from './UploadInputFieldProps';
import SideBarService from "../SideBar/SideBarService";
import SideBarData from "../SideBar/SideBarData";


const useStyles = makeStyles((theme: Theme) => ({
    label: {
        display: 'flex',
        [theme.breakpoints.only('xs')]: {
            paddingBottom: '0 !important'
        }
    },
    required: {
        color: 'red',
        fontWeight: 'bold'
    },
    input: {
        backgroundColor: 'white',
        [theme.breakpoints.only('xs')]: {
            paddingTop: '0 !important'
        }
    },
    select: {
        backgroundColor: 'white',
        [theme.breakpoints.only('xs')]: {
            paddingTop: '0 !important'
        }
    }
}));

function UploadInputField(props: UploadInputFieldProps & WithWidthProps) {

    const classes = useStyles();
    const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();  
    const [error, setError] = React.useState<string>();

    useEffect(() => {
        const loadMainAppItems = async () => {
          try {
          const appMainMenuItemsQuery = await SideBarService.getAllApplications();
          const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
          setAppMenuItems(appMainMenuItems?.items as SideBarData[]);
         
          console.log(appMenuItems);
          }catch (err) {
            const message = "Error loading data";
            setError(`${message}: ${err}`);
            console.log(message, err);
          }
    
        };
        loadMainAppItems();  
        });

    const renderControl = (type: string | undefined) => {
        switch (type) {
            case 'file':
                return (<Button variant="contained" color="primary" component="label">
                    Select {props.label}
                    <input required type="file" name="file" onChange={props.onChange} accept={props.accept} style={{ display: "none" }} />
                </Button>);
            case 'select':
                return (<TextField id="select" size="small" fullWidth rows={props.rows} select onChange={props.onChange} className={classes.select}>
                    <MenuItem value="">Select</MenuItem>
                    {appMenuItems &&
        appMenuItems.map((appMenuItem, appMenuItemIndex) => (
                <MenuItem value={appMenuItem.appName}>{appMenuItem.appName}</MenuItem>
                ))} 
              </TextField>);
            case 'tags':
                return (<TagContainer onChange={props.onChange} edit={false} existingTags={null} className={null}  />);
            default:
                return (<TextField size="small" fullWidth rows={props.rows} multiline={props.multiline} variant="outlined" className={classes.input} onChange={props.onChange} />);
        }
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={props.labelWidth} justify={props.width !== 'xs' ? 'flex-end' : undefined} className={classes.label}>
                <Typography variant="h6">{props.label} <sup className={classes.required}>{props.required ? '*' : '\u00A0'}</sup></Typography>
            </Grid>
            <Grid item xs={12} sm={(12 - props.labelWidth) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12}>
                {renderControl(props.type)}
            </Grid>
        </Grid>
    );
}

export default withWidth()(UploadInputField);