import Storage from "@aws-amplify/storage";
import { Button, makeStyles, Theme, Typography } from '@material-ui/core';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from "react-router-dom";
import { CreateApplicationInput, CreateResourceInput, UpdateResourceInput } from '../../API';
import BusyIndicator from "../BusyIndicator/BusyIndicator";
import BusyIndicatorProps from '../BusyIndicator/BusyIndicatorProps';
import ResourceService from '../ResourceGrid/ResourceService';
import UploadInputField from "./UploadInputField";
import UploadPageProps from './UploadPageProps';

const useStyles = makeStyles((theme: Theme) => ({
    form: {
        maxWidth: 600
    },
    required: {
        color: 'red',
        fontWeight: 'bold'
    },
}));

interface Props extends RouteComponentProps, UploadPageProps { };

const UploadPage = (props: Props) => {

    const classes = useStyles();

    const [busyIndicator, setBusyIndicator] = useState<BusyIndicatorProps>({} as BusyIndicatorProps); // 'Busy' indicator
    const [error, setError] = useState<string>(''); // Any error message from fetch / save

    const [title, setTitle] = useState<string>('');
    const handleTitleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setTitle(e.target.value);
    }

    const [description, setDescription] = useState<string>('');
    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setDescription(e.target.value);
    }

    const [thumbnailFile, setThumbnailFile] = useState<File>();
    const handleThumbnailChange = (e: ChangeEvent<HTMLInputElement>) => {
        setThumbnailFile(e.target.files?.item(0) || undefined);
    }

    const [resourceFile, setResourceFile] = useState<File>();
    const handleResourceChange = (e: ChangeEvent<HTMLInputElement>) => {
        setResourceFile(e.target.files?.item(0) || undefined);
    }
    const [appAsString, setappAsString] = useState<string>('');
    const handleApplicationChange =  (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setappAsString(e.target.value);
    }

    const [tags, setTags] = useState<string[]>();
    const handleTagsChange = (tags: string[]) => {
        setTags(tags);
    }

    const [groups, setGroups] = useState<string[]>();
    useEffect(() => {
        if (!groups) {
            setGroups(props.user?.getSignInUserSession()?.getAccessToken().payload["cognito:groups"]);
        }
    });

    const uploadFileToS3 = async (file: File, id: string) => {
        const extension = file.name.split('.').pop();
        const fileName = id + '.' + extension;
        const rootPath = props.type === 'video' ? 'videos' : 'documents';
        await Storage.put(`${rootPath}/${fileName}`, file, { contentType: file.type });
        return `${rootPath}/` + fileName;
    }

    const handleSubmit = async () => {
        try {
            setBusyIndicator({ busy: true, message: 'Uploading file...' });
            if (thumbnailFile && resourceFile) {
                const resource = {
                    type: props.type,
                    title,
                    description,
                    tags,
                    appAsString
                } as CreateResourceInput;
               
                const createResourceMutation = await ResourceService.createResource(resource);
                
                if (createResourceMutation.data?.createResource) {
                    const id = createResourceMutation.data.createResource.id;
                    const createdResource = {
                        ...createResourceMutation.data.createResource,
                        thumbnailPath: await uploadFileToS3(thumbnailFile, id),
                        resourcePath: await uploadFileToS3(resourceFile, id)
                    } as UpdateResourceInput;
                    await ResourceService.updateResource(createdResource);
                    try {
                        props.history.push(`/${props.type}/` + id);
                    } catch (err) {
                        console.log('Redirect error', err);
                    }
                } else {
                    setError('Could not get resource id');
                }
            }
        } catch (err:any) {
            console.log('Error', err);
            if (err.errors[0]) {
                setError(err.errors[0].message);
            } else {
                setError('An error occurred while uploading the file');
            }
        } finally {
            setBusyIndicator({ busy: false });
        }
    }

    return (
        <React.Fragment>
            {groups && groups?.indexOf('Admin') >= 0
                ?
                <div className={classes.form}>
                    <Typography variant="h4" gutterBottom>Upload {props.type === 'video' ? 'Video' : 'Document'}</Typography>

                    <br />

                    <p style={{ color: "red" }} hidden={!error}>An error has occurred: {error}</p>

                    <UploadInputField required label="Title" labelWidth={3} onChange={handleTitleChange} />
                    <UploadInputField required rows={4} multiline label="Description" labelWidth={3} onChange={handleDescriptionChange} />

                    <UploadInputField required label="Thumbnail" type="file" labelWidth={3} onChange={e => handleThumbnailChange(e as ChangeEvent<HTMLInputElement>)} accept=".jpg" />
                    <UploadInputField required label={props.type === 'video' ? 'Video' : 'Document'} type="file" labelWidth={3}
                        onChange={e => handleResourceChange(e as ChangeEvent<HTMLInputElement>)} accept={props.type === 'video' ? '.mp4' : '.pdf'} />
                    <UploadInputField required label="Application" labelWidth={3} type="select" onChange={handleApplicationChange} />
                    <UploadInputField label="Tags" type="tags" labelWidth={3} onChange={handleTagsChange} />

                    <br />
                    <br />

                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!title || !description || !thumbnailFile || !resourceFile}
                        onClick={handleSubmit}>Submit</Button>

                </div>
                :
                <div>Access denied</div>}
            <BusyIndicator busy={busyIndicator.busy} message={busyIndicator.message} />
        </React.Fragment>
    );
}

export default withRouter(UploadPage);