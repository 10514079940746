import { Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import React from 'react';

export interface InfoCardDetailData {
    label: string;
    value: string | React.ReactElement;
}

interface Props {
    existsInCurrent?: boolean;
    filedInError?: boolean | null;
    data: InfoCardDetailData[];
    onFiledInError?: () => Promise<void>;
    onClose?: () => Promise<void>;
    hasAccess?: boolean;
}

export default function InfoCardDetail(props: Props) {

    const handleFiledInError = async () => {
        if (props.onFiledInError) {
            await props.onFiledInError();
        }
        if (props.onClose) {
            props.onClose();
        }
    };

    return (
        <React.Fragment>
            <TableContainer style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
                <Table>
                    <TableBody>
                        {props.data.map(d => (
                            <TableRow>
                                <TableCell align="left"><strong>{d.label}</strong></TableCell>
                                <TableCell align="left">{d.value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    );
}
