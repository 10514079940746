import {
  Box,
  createMuiTheme,
  CssBaseline,
  Link,
  makeStyles,
  MuiThemeProvider,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import { CognitoUser } from "amazon-cognito-identity-js";
import Amplify, { Auth, Hub } from "aws-amplify";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { BreadcrumbsRoute } from "react-router-breadcrumbs-hoc";
import { BrowserRouter as Router, Route, Switch,Redirect } from "react-router-dom";
import config from "./aws-exports";
import AdminPage from "./Components/Admin/AdminPage";
import BreadcrumbBar from "./Components/BreadcrumbBar/BreadcrumbBar";
import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";
import DocumentPage from "./Components/ResourceGrid/Parts/Document/DocumentPage";
import VideoPage from "./Components/ResourceGrid/Parts/Video/VideoPage";
import ResourceGrid from "./Components/ResourceGrid/ResourceGrid";
import SideBar from "./Components/SideBar/SideBar";
import UploadPage from "./Components/Upload/UploadPage";
import EHWCLinks from "./Components/LandingPages/WorkDay/WDEHWC";
import EmployeeLinks from "./Components/LandingPages/WorkDay/WDEmployee";
import HRBusPartnerLinks from "./Components/LandingPages/WorkDay/WDHRBusPartner";
import ManagerLinks from "./Components/LandingPages/WorkDay/WDManager";
import RecruiterLinks from "./Components/LandingPages/WorkDay/WDRecruiter";
import FinanceLinks from "./Components/LandingPages/WorkDay/WDFinance";
import SDCLinks from "./Components/LandingPages/WorkDay/WDSDC";
import EMSLinks from "./Components/LandingPages/EMS/EMSEvents";
import { withAuthenticator, AmplifyProvider } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
import "@aws-amplify/ui-react/styles.css";
import "./signinpage.css";
import logo from "./Components/Header/Resources/logo-horiz.png";
import ChannelGrid from "./Components/ResourceGrid/ChannelGrid";

//TODO MOVE INTO Components
import VideoService from "./Components/ResourceGrid/Parts/Video/VideoService";
import ResourceService from "./Components/ResourceGrid/ResourceService";

console.log("ResourceService::", ResourceService);

Amplify.configure(awsmobile);

const drawerWidth = 250;

const theme = createMuiTheme({
  palette: {
    text: {
      primary: "rgb(20, 59, 89)",
    },
    background: {
      default: "white",
    },
  },
});

theme.typography.h2 = {
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.65rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1.4rem",
  },
};

theme.typography.subtitle1 = {
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.4rem",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "1rem",
  },
};

const useStyles = makeStyles((theme) => ({
  main: {},
  logo: {
    width: 265,
    height: 'auto',
    marginRight: 25
  },
  container: {
    [theme.breakpoints.down("xs")]: {
      width: "100% !important",
    },
    paddingLeft: "2%",
    paddingRight: "2%",
    marginTop: 35,
    width: `calc(100% - ${drawerWidth}px)`,
  },

  signOutBtn: {
    // width: "150px",
    width: "100px",
    padding: "5px 5px",
    margin: "8px 0",
    display: "inline-block",
    border: "1px solid #ccc",
    borderRadius: "4px",
    boxSizing: "border-box",
    cursor: "pointer",

    marginRight: "50px",
    float: "right",
    fontSize: "20px",
  },
  errorMessage: {
    color: "red",
  },
  infoContainer: {
    margin: "30px auto",
    fontSize: "20px",
    maxWidth: "400px",
  },
  videoMessageContainer: {
    margin: "30px auto 30px 60px",
    fontSize: "20px",
    // maxWidth: "40px",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.ericksonseniorliving.com/">
        Erickson Living
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// START AUTH PREP
let cognitoUser: any = CognitoUser; // Track authentication flow state in this object
const initialFormState = {
  username: "",
  password: "",
  email: "",
  authCode: "",
  formType: "",
  userPhone: "",
};

console.log("###cognitoUser.challengeParam:", cognitoUser.challengeParam);

// paramValue
let userEmail = "";
const urlParams = new URLSearchParams(window.location.search);
const paramValue = urlParams.get("e");
if (paramValue) {
  userEmail = paramValue || "";
}

console.log("###cognitoUser:", cognitoUser);
// END AUTH PREP

const App: React.FC = (props) => {
  const classes = useStyles();

  // const [user, setUser] = useState<CognitoUser>();

  // const logout = useCallback(() => Auth.signOut(), []);

  const logout = () => {
    Auth.signOut();
  };

  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case "customOAuthState":
        const targetUrl = localStorage.getItem("targetUrl") || "/";
        localStorage.removeItem("targetUrl");
        window.location.pathname = targetUrl;
    }
  });

  useEffect(() => { }, []);

  const breadcrumRoutes = [
    { path: "/workday", breadcrumb: "Workday Quick Links" },
    { path: "/document", breadcrumb: "Training Documents" },
    { path: "/document/:id", breadcrumb: "Document" },
    { path: "/video", breadcrumb: "Training Videos" },
    { path: "/video/:id", breadcrumb: "Video" },
    { path: "/video-upload", breadcrumb: "Video Upload" },
    { path: "/document-upload", breadcrumb: "Document Upload" },
    { path: "/admin", breadcrumb: "Admin Console" },
    { path: "/workday/employee", breadcrumb: "Employee" },
    { path: "/workday/manager", breadcrumb: "Manager" },
    { path: "/workday/hrbuspartner", breadcrumb: "HR Business Partner" },
    { path: "/workday/ehwc", breadcrumb: "EHWC" },
    { path: "/workday/sdc", breadcrumb: "SDC" },
    { path: "/workday/recruiter", breadcrumb: "Recruiter" },
    { path: "/workday/finance", breadcrumb: "Finance" },
    { path: "/ems/emsmyevents", breadcrumb: "EMS/My Events" },
  ] as BreadcrumbsRoute[];

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const handleMenuClick = () => {
    setMenuVisible(!menuVisible);
  };

  const App = () => {
    // START MIKE'S AUTH CODE
    const [formState, updateFormState] = useState(initialFormState);
    const [user, updateUser] = useState(null); //useState<any[]>([]); //
    const [serverMessage, updateServerMessage] = useState(""); //useState<any[]>([]); //
    const [numberOfWatchedVideos, setNumberOfWatchedVideos] = useState<any>();
    const [numberOfTotalVideos, setNumberOfTotalVideos] = useState<any>();
    const [videoPath, setVideoPath] = useState<String>();

    const checkUser = async () => {
      try {
        const user: any = await Auth.currentAuthenticatedUser();

        if (user) {
          (user as CognitoUser).getUserAttributes((err, ua) => {
            const studentAttr = ua?.find(att => att.getName() === 'custom:student');
            console.log(studentAttr);
            if (studentAttr?.getValue() === 'true' ) {
              setVideoPath('Student');
            } else {
              setVideoPath('IL');
            }
          });
        }
        updateUser(user);
        console.log("got user", user);

        if (user) {
          VideoService.getNumberOfWatchedVideos(user.username).then((nw) =>
            setNumberOfWatchedVideos(nw)
          );
          VideoService.getAllResources().then((nw) =>
            setNumberOfTotalVideos(nw)
          );

          // const searchResourcesQuery = await ResourceService.getAllResources(props.resourceType, searchText, pageSize, pageToken):
          // await ResourceService.searchResources(props.resourceType, appType, searchText, pageSize, pageToken);

          // const searchResources = searchResourcesQuery.data?.searchResources;
          // console.log('searchResources:::', searchResources);
        }

        updateFormState(() => ({ ...formState, formType: "signedIn" }));
      } catch (err) {
        console.log("checkUser error", err);
        updateFormState(() => ({ ...formState, formType: "signIn" })); //"signUp" signIn
      }
    };
    // Skip this if not using Hub ... call updateFormState function right
    // after the Auth.signOut() call in the button.
    const setAuthListener = async () => {
      Hub.listen("auth", (data) => {
        switch (data.payload.event) {
          case "signOut":
            console.log(data);

            updateFormState(() => ({
              ...formState,
              formType: "signIn",
            }));
            break;
          case "signIn":
            console.log(data);
            break;
        }
      });
    };

    useEffect(()  => {
      
      setAuthListener();
      checkUser();
    }, []);

    const onChange = (e: any) => {
      e.persist();
      console.log("e.target.value:::", e.target.value);
      updateFormState(() => ({
        ...formState,
        [e.target.name]: e.target.value,
      }));
    };
    const { formType } = formState;

    const signUp = async () => {
      const { username, email, password } = formState;

      console.log("signUp, username:", username);

      await Auth.signUp({ username, password, attributes: { email } });
      //here 
      updateServerMessage(
        ""
      );
      updateFormState(() => ({ ...formState, formType: "confirmSignUp" }));

    };

    const confirmSignUp = async (event: any) => {
      event.preventDefault()
      const { username, authCode } = formState;
      console.log("in confirmSignUp, authCode", authCode);
      answerCustomChallenge(authCode);
    };



  

    const signIn = async (event: any) => {
      event.preventDefault()
      let { email, password } = formState;

      if (email == "" && userEmail != "" && userEmail.length > 0) {
        email = userEmail;
      }

      console.log("in SignIn, email:", email);
      console.log("in SignIn, initial cognitoUser:", cognitoUser);

      cognitoUser = await Auth.signIn(email).catch((err) => {
        console.error("ttttttt::::", err);
        updateServerMessage(
          "Oops! It looks like something went wrong. Reminder, the sign-in is the email you used to apply in Workday. If the problem persists, please contact the HR"
        );
      });
      console.log("in SignIn, cognitoUser:", cognitoUser);
      if (cognitoUser) {
        console.log("in SignIn, cognitoUser.username:", cognitoUser.username);

        updateFormState(() => ({ ...formState, formType: "confirmSignUp" }));
      }

      try {
        const get = function (obj: object, key: any) {
          return key.split(".").reduce(function (o: any, x: any) {
            return typeof o == "undefined" || o === null ? o : o[x];
          }, obj);
        };

        if (get(cognitoUser, "challengeParam.phone")) {
          // let { userPhone } = formState;
          console.log(
            "in SignIn, cognitoUser.challengeParam:",
            cognitoUser.challengeParam
          );
          console.log(
            "in SignIn, cognitoUser.challengeParam.phone:",
            cognitoUser.challengeParam.phone
          );
          initialFormState.userPhone = cognitoUser.challengeParam.phone;
          console.log("in SignIn, cognitoUser:", cognitoUser);
          let phone = cognitoUser["challengeParam.phone"];
        }
      } catch (error) {
        console.log("cognitouser getting phone, error:", error);
      }

      if (cognitoUser && cognitoUser.hasOwnProperty("CognitoUser")) {
        console.log("in SignIn, cognitoUser2:", cognitoUser);
      }
    };

    async function answerCustomChallenge(answer: string) {
      console.log("answerCustomChallenge, answer", answer);

      // Send the answer to the User Pool
      // This will throw an error if it’s the 3rd wrong answer
      cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, answer)
        .then((user) => {
          console.log("sendCustomChallengeAnswer, user", user);
          return user;
        })
        .catch((err) => {
          console.log("sendCustomChallengeAnswer, err", err);
          updateServerMessage(
            "Something went wrong. Contact your system administrator."
          );
          updateFormState(() => ({ ...formState, formType: "signIn" }));
        });
      console.log(
        "The user, cognitoUser?.signInUserSession:::",
        cognitoUser?.signInUserSession
      );
      if (cognitoUser?.signInUserSession) {
        console.log(
          "The user entered the right code, cognitoUser:::",
          cognitoUser
        );
        updateFormState(() => ({ ...formState, formType: "signedIn" }));
        updateServerMessage('')
        window.location.reload();
      } else {
        // console.log("The user entered the wrong code, cognitoUser:::", cognitoUser);
        // cognitoUser = null
        updateServerMessage('Please check the code and try again.')

        // updateServerMessage('Something went wrong. Contact your system administrator.')
        // setTimeout(() => {
        //   updateFormState(() => ({ ...formState, formType: "signIn" }));
        // }, 3000);
      }
    }

    // START MIKE'S AUTH CODE
    return (
      <div className="App">
        <header className="App-header">
          <div className="signinpage">
            {formType === "signUp" && (
              <div>

                <h1>Welcome to e-orientation</h1>
                <input
                  name="username"
                  onChange={onChange}
                  placeholder="username"
                />
                <input
                  name="password"
                  type="password"
                  onChange={onChange}
                  placeholder="password"
                />
                <input name="email" onChange={onChange} placeholder="email" />

                <button onClick={signUp}>Sign Up</button>
                <img src={logo} className={classes.logo} />
                <p>Already signed up?</p>

                <button
                  onClick={() =>
                    updateFormState(() => ({
                      ...formState,
                      formType: "signIn",
                    }))
                  }
                >
                  Sign In instead
                </button>
              </div>
            )}

            {formType === "confirmSignUp" && (
              <form onSubmit={signIn}>
              <div>
                <img src={logo} className={classes.logo} alt="" />
                <h1>Welcome to e-orientation</h1>
                <div className={classes.infoContainer}>
                  Success! Please check your email and or phone for your 6-digit verification code.
                </div>
                <input
                  type="text"
                  name="authCode"
                  onChange={onChange}
                  placeholder="Enter auth code"
                />
                <button onClick={confirmSignUp}>Confirm</button>
                <br />
                <br />
                <div className={classes.errorMessage}>{serverMessage}</div>
              </div>
              </form>
            )}

            {formType === "signIn" && (
              <form onSubmit={signIn}>
              <div>
                <img src={logo} className={classes.logo} alt="" />
                <h1>Welcome to e-orientation</h1>
                <h3>Sign In</h3>
                <br />
                <input
                  type="text"
                  defaultValue={userEmail}
                  name="email"
                  onChange={onChange}
                  placeholder="email address"
                />
                {/* <button type="submit" onClick={signIn}>
                  Sign In
                </button> */}
                <input type="submit" value="Sign In" />
                <br />
                <br />
                <div className={classes.errorMessage}>{serverMessage}</div>

                <br />
              </div>
              </form>
            )}

            {formType === "confirmSignIn" && ( //need to trigger
              <div>

                <h1>Welcome to e-orientation</h1>
                <input
                  type="text"
                  name="authCode"
                  onChange={onChange}
                  placeholder="OTP code"
                />
                {/* <button onClick={signIn}>Confirm</button> */}
              </div>
            )}
          </div>

          {formType === "signedIn" && (
            <div>
              {/* <h2>Welcome to Erickson Orientation</h2> */}

              <AmplifyProvider theme={MyTheme}>
                <Router>
                  <ThemeProvider theme={theme}>
                    <CssBaseline />

                    {user && (
                      <main>
                        <Header
                          user={user}
                          onLogOut={logout}
                          onMenuClick={handleMenuClick}
                        />

                        {/* <button
                          className={classes.signOutBtn}
                          onClick={() => {
                            Auth.signOut();
                          }}
                        >
                          Sign out
                        </button> */}

                        <div id="mainContainer" className={classes.container}>
                          {/* <BreadcrumbBar routes={breadcrumRoutes} /> */}
                          <div className={classes.videoMessageContainer}>
                            {/* Welcome! Now, let’s learn more about your new community and the important role you’ll play. */}

                            {/* {numberOfWatchedVideos}/{numberOfTotalVideos} */}

                            <br />
                            <br />
                            {/* if user is on first video, then */}
                            {/* //Let’s get started with video 1. */}
                            {/* else, 
                            Next step, video 2.
                             */}
                          </div>
                          <Switch>
                            <Route exact path="/admin" component={AdminPage} />
                            <Route
                              exact
                              path="/video-upload"
                              render={() =>
                                user && (
                                  <UploadPage
                                    type="video"
                                    user={user}
                                    {...props}
                                  />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/document-upload"
                              render={() =>
                                user && (
                                  <UploadPage
                                    type="document"
                                    user={user}
                                    {...props}
                                  />
                                )
                              }
                            />
                            <Route
                              exact
                              path={`/video/:id`}
                              component={VideoPage}
                            />
                            <Route
                              exact
                              path="/document/:id"
                              component={DocumentPage}
                            />
                            <Route
                              exact
                              path="/document"
                              render={() =>
                                user && (
                                  <ResourceGrid
                                    user={user}
                                    resourceType="document"
                                  />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/channel/:name"
                              render={() =>
                                user && (
                                  <ChannelGrid
                                    user={user}
                                    resourceType="video"
                                  />
                                )
                              }
                            />
                         <Route
                              exact
                              path="/"
                              render={() =>
                                videoPath && (
                                <Redirect to={`/channel/${videoPath}`} /> 
                                )
                                
                              }
                            />




                            {/* <Route exact path="/" component={Home} /> */}
                          </Switch>
                          <Box pt={1} mt={20}>
                            <Copyright />
                          </Box>
                        </div>
                      </main>
                    )}

                    {/* {user && (
                      <div>
                        <SideBar
                          drawerWidth={drawerWidth}
                          user={user}
                          menuVisible={menuVisible}
                          onLogout={logout}
                          onNavigate={() => {
                            setMenuVisible(false);
                          }}
                        />
                      </div>
                    )} */}
                  </ThemeProvider>
                </Router>
              </AmplifyProvider>
            </div>
          )}
        </header>
      </div>
    );
    //   return (
    //     <AmplifyProvider  theme={MyTheme}>
    //     <Router>
    //       <ThemeProvider theme={theme}>
    //         <CssBaseline />

    //         <Header user={user} onLogOut={logout} onMenuClick={handleMenuClick} />
    //         {user &&
    //         <main>

    //           <div id="mainContainer" className={classes.container}>
    //             <BreadcrumbBar routes={breadcrumRoutes} />
    //             <Switch>
    //               <Route exact path="/admin" component={AdminPage} />
    //               <Route
    //                 exact
    //                 path="/video-upload"
    //                 render={() => user && <UploadPage type="video" user={user} {...props} />}
    //               />
    //               <Route
    //                 exact
    //                 path="/document-upload"
    //                 render={() => user && <UploadPage type="document" user={user} {...props} />}
    //               />
    //               <Route exact path="/video/:id" component={VideoPage} />
    //               <Route exact path="/video" render={() => user && <ResourceGrid user={user} resourceType="video" />} />
    //               <Route exact path="/document/:id" component={DocumentPage} />
    //               <Route
    //                 exact
    //                 path="/document"
    //                 render={() => user && <ResourceGrid user={user} resourceType="document" />}
    //               />
    //               <Route exact path="/workday/employee" component={EmployeeLinks} />
    //               <Route exact path="/workday/manager" component={ManagerLinks} />
    //               <Route exact path="/workday/hrbuspartner" component={HRBusPartnerLinks} />
    //               <Route exact path="/workday/ehwc" component={EHWCLinks} />
    //               <Route exact path="/workday/sdc" component={SDCLinks} />
    //               <Route exact path="/workday/recruiter" component={RecruiterLinks} />
    //               <Route exact path="/workday/finance" component={FinanceLinks} />
    //               <Route exact path="/ems/myevents" component={EMSLinks} />
    //               <Route exact path="/" component={Home} />
    //             </Switch>
    //             <Box pt={1} mt={20}>
    //               <Copyright />
    //             </Box>
    //           </div>
    //         </main>
    // }

    //         <SideBar
    //           drawerWidth={drawerWidth}
    //           user={user}
    //           menuVisible={menuVisible}
    //           onLogout={logout}
    //           onNavigate={() => {
    //             setMenuVisible(false);
    //           }}
    //         />
    //       </ThemeProvider>
    //     </Router>
    //     </AmplifyProvider>
    //   );
  };

  return (
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  );
};

const MyTheme = {
  name: "eNeo",
  button: { backgroundColor: "blue", borderColor: "red" },
  signInButtonIcon: { display: "none" },
};

export default App;

// export default withAuthenticator(App);

// export default withAuthenticator(App,<AmplifyAuthenticator >
//   {/* don't include phone number for signup */}
//   <AmplifySignUp
//     headerText="ESL Orientation: New Account"
//     slot="sign-up"
//   ></AmplifySignUp>

//       <AmplifySignIn slot="sign-in" usernameAlias="email" />
// </AmplifyAuthenticator>

// );
