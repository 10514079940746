import { createMuiTheme, Grid, IconButton, MuiThemeProvider, Popover } from '@material-ui/core';
import { DeleteForever, PowerInputSharp } from '@material-ui/icons';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MaterialTable, { Action, Column, Options } from "@material-table/core";
import React, { forwardRef, useState } from "react";
import GenericFilter, { AppliedFilter, AppliedFilterOptions } from './GenericFilter';
import InfoCardDetail, { InfoCardDetailData } from './InfoCardDetail';

const tableIcons = {
    Add: forwardRef((props, ref: any) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref: any) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref: any) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref: any) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref: any) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref: any) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref: any) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref: any) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref: any) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref: any) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref: any) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref: any) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref: any) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref: any) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref: any) => <ViewColumn {...props} ref={ref} />)
} as any;

const theme = createMuiTheme({
    overrides: {
        MuiTableSortLabel: {
            root: {
                fontWeight: 'bold',
                color: '#fff',
                "&:hover": {
                    color: '#fff',
                    '&& $icon': {
                        opacity: 1,
                        color: '#fff'
                    },
                },
                "&$active": {
                    color: '#fff',
                    // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
                    '&& $icon': {
                        opacity: 1,
                        color: '#fff'
                    },
                },
            },
        },
        MuiCheckbox: {
            root: {
                color: 'rgb(161, 161, 161)'
            }
        }
    }
});

export interface CustomColumn<T extends object> extends Column<T> {
    advancedFilter?: boolean;
    customFilter?: (filter: AppliedFilter<T>) => Promise<object>;
}

export interface GenericTableProps<T extends object, F extends object> {
    title?: string | React.ReactElement<any, any>;
    data: T[];
    columns: CustomColumn<T>[];
    onRowClick?: (event?: React.MouseEvent, rowData?: T, toggleDetailPanel?: (panelIndex?: number) => void) => void;
    isLoading?: boolean;
    onViewInfoCard?: (rowData: T) => Promise<{ label: string, value: string | React.ReactElement }[]>;
    onDeleteInfoCard?: any,
    onRowUpdate?: (newData: T, oldData?: T | undefined) => Promise<any>;
    customActions?: (Action<T> | ((rowData: T) => Action<T>))[];
    selectionProps?: (rowData: T) => any;
    selection?: boolean;
    grouping?: boolean;
    optionOverrides?: Options<T>;
    onFilter?: (filter?: F) => void;
    onConditionalFilter?: (filter?: (options?: AppliedFilterOptions) => F) => void;
    onRefresh?: () => void;
}

const GenericTable = <T extends object, F extends object>(props: GenericTableProps<T, F>) => {

    const [anchorEl, setAnchorEl] = useState(null);

    const tableRef = React.createRef();

    const [cardData, setCardData] = useState<InfoCardDetailData[] | null>(null);

    const handlePreview = async (event: any, rowData: T | T[]) => {
        setAnchorEl(event.currentTarget);
        if (props.onViewInfoCard) {
            if (rowData as T) {
                setCardData(await props.onViewInfoCard(rowData as T));
            } else {
                throw new Error('Not implemented');
            }
        }
    };
    const handleDelete = async (event: any, rowData: T | T[]) => {
        if (props.onDeleteInfoCard) {
            await props.onDeleteInfoCard(rowData);
        }
    };

    /*const deselectAll = () => {
        // @ts-ignore
        tableRef.current.dataManager.changeAllSelected(true);
    }*/

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const getActions = () => {
        const actions: (Action<T> | ((rowData: T) => Action<T>))[] = [];
        if (props.onDeleteInfoCard) {
            actions.push(
                {
                    icon: () => <DeleteForever />,
                    tooltip: 'Delete',
                    onClick: async (event, data) => await handleDelete(event, data),
                    position: 'row'
                }
            )
        }
        if (props.onViewInfoCard) {
            actions.push({
                icon: () => <VisibilityIcon />,
                tooltip: 'Preview',
                onClick: async (event, data) => await handlePreview(event, data),
                position: 'row'
            });
        }
        if (props.customActions) {
            actions.push(...props.customActions);
        }
        return actions;
    }

    return (
        <div style={{ maxWidth: "100%" }}>
            <Grid container>
                <Grid item xs={11}>
                    {(props.onFilter || props.onConditionalFilter) &&
                        <GenericFilter<T, F> columns={props.columns} onFilter={props.onFilter} onConditionalFilter={props.onConditionalFilter} />
                    }
                </Grid>
                <Grid item style={{ textAlign: 'right' }} xs={1}>
                    {props.onRefresh &&
                        <IconButton style={{ marginTop: '10px' }} size="small" color="primary" onClick={props.onRefresh}>
                            <RefreshIcon />
                        </IconButton>
                    }
                </Grid>
            </Grid>
            <MuiThemeProvider theme={theme}>
                <MaterialTable
                    tableRef={tableRef}
                    icons={tableIcons}
                    columns={props.columns}
                    data={props.data}
                    onRowClick={props.onRowClick}
                    // onSelectionChange={(rows) => alert('You selected ' + rows.length + ' rows')}
                    options={{
                        search: false,
                        pageSize: 15,
                        headerStyle: {
                            backgroundColor: 'rgb(20, 59, 89)',
                            color: '#fff'
                        },
                        rowStyle: (_, index) => ({
                            backgroundColor: (index % 2) ? '#DDD' : '#EEE'
                        }),
                        padding: 'dense',
                        filtering: false,
                        toolbar: props.title !== undefined,
                        selection: props.selection,
                        selectionProps: props.selectionProps,
                        grouping: props.grouping,
                        ...props.optionOverrides,
                        groupTitle: (groupData: any) => {
                            if (groupData && Array.isArray(groupData.value)) {
                                return groupData.value.sort().join(', ');
                            }
                            return groupData.value;
                        }
                    }}
                    title={props.title}
                    isLoading={props.isLoading}
                    actions={getActions()}
                    editable={props.onRowUpdate ? {
                        onRowUpdate: (newData, oldData) => {
                            return props.onRowUpdate ? props.onRowUpdate(newData, oldData) : new Promise(_ => _);
                        }
                    } : undefined}
                />
                <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    {cardData &&
                        <InfoCardDetail
                            data={cardData}
                        />
                    }
                </Popover>
            </MuiThemeProvider>
        </div>
    );
}

export default GenericTable;