import { API, Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider } from 'aws-sdk';
import { GroupType, UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider';

export default class AdminService {

    private constructor() {

    }

    static async addUserToGroup(username: string, groupname: string) {
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/addUserToGroup', myInit);
    }

    static async removeUserFromGroup(username: string, groupname: string) {
        const myInit = {
            body: {
                username,
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/removeUserFromGroup', myInit);
    }

    static async listGroups() {
        const myInit = {
            queryStringParameters: {
                limit: 60,
                token: undefined as undefined | string
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        const groups = [] as GroupType[];
        do {
            const rest = await API.get('AdminQueries', '/listGroups', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
            myInit.queryStringParameters.token = rest.NextToken;
            groups.push(...rest.Groups as GroupType[]);
        } while (myInit.queryStringParameters.token !== undefined);
    
        return groups as CognitoIdentityServiceProvider.GroupListType;
    }

    static async getGroupsForUser(username: string) {
        const myInit = {
            queryStringParameters: {
                username,
                limit: 60,
                token: undefined as undefined | string
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        const groups = [] as GroupType[];
        do {
            const rest = await API.get('AdminQueries', '/listGroupsForUser', myInit) as CognitoIdentityServiceProvider.ListGroupsResponse;
            myInit.queryStringParameters.token = rest.NextToken;
            groups.push(...rest.Groups as GroupType[]);
        } while (myInit.queryStringParameters.token !== undefined);
        return groups as CognitoIdentityServiceProvider.GroupListType;
    }

    static async listUsers() {
        const myInit = {
            queryStringParameters: {
                limit: 60,
                token: undefined as undefined | string
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }

        const users = [] as UserType[];
        do {
            const rest = await API.get('AdminQueries', '/listUsers', myInit) as CognitoIdentityServiceProvider.Types.ListUsersResponse;
            // @ts-ignore
            myInit.queryStringParameters.token = rest.NextToken;
            users.push(...rest.Users as UserType[]);
        } while (myInit.queryStringParameters.token !== undefined);
        return users;
    }

    static async createGroup(groupname: string, description?: string, precedence?: number) {
        const myInit = {
            body: {
                groupname,
                description,
                precedence
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/createGroup', myInit) as CognitoIdentityServiceProvider.CreateGroupResponse;
    }

    static async deleteGroup(groupname: string) {
        const myInit = {
            body: {
                groupname
            },
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        }
        return API.post('AdminQueries', '/deleteGroup', myInit) as CognitoIdentityServiceProvider.CreateGroupResponse;
    }

}
