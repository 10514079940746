import { Box, Button, Chip, Grid, Grow, IconButton, MenuItem, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import makeStyles from "@material-ui/core/styles/makeStyles";
import FilterListIcon from '@material-ui/icons/FilterList';
import { Column } from "@material-table/core";
import React, { Fragment, useEffect, useState } from "react";
import { CustomColumn } from "./GenericTable";

interface Props<T extends object, F extends object> {
    columns: Column<T>[];
    onFilter?: (filter?: F) => void;
    onConditionalFilter?: (filter?: (options?: AppliedFilterOptions) => F) => void;
}

export interface AppliedFilter<T extends object> {
    column: CustomColumn<T>;
    filterText: string;
}

export interface AppliedFilterOptions {
    excludedFields?: string[]
}

const useStyles = makeStyles((theme) => ({
    chipContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}));

const GenericFilter = <T extends object, F extends object>(props: Props<T, F>) => {

    const classes = useStyles();

    const [selectedSearchField, setSelectedSearchField] = useState<string | null>(null);
    const handleSelectedSearchFieldChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        setSelectedSearchField(event.target.value as string);
    }

    const [filterText, setFilterText] = useState<string>('');
    const handleFilterTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterText(event.target.value);
    }

    const [appliedFilters, setAppliedFilters] = useState<AppliedFilter<T>[]>([]);
    const handleApplyFilter = () => {
        const columnForSelectedSearchField = props.columns.find(col => col.field === selectedSearchField);
        if (columnForSelectedSearchField) {
            const filter = {
                column: columnForSelectedSearchField,
                filterText
            } as AppliedFilter<T>;
            const filterJson = JSON.stringify(filter);
            const existingFilter = appliedFilters.find(af => JSON.stringify(af) === filterJson);
            if (!existingFilter) {
                setAppliedFilters(appliedFilters.concat(filter));
            }
            setSelectedSearchField(null);
            setFilterText('');
        }
    }

    const handleAppliedFilterClick = (filter: AppliedFilter<T>) => {
        setSelectedSearchField(filter.column.field as string);
        setFilterText(filter.filterText);
    }

    const handleDelete = (index: number) => {
        setAppliedFilters(appliedFilters.filter((_, i) => i !== index));
    }

    useEffect(() => {
        const constructFilters = async () => {
            if (props.onFilter || props.onConditionalFilter) {
                //commented by Michael
                // if (appliedFilters.length > 0 ) { 
                //     const constructedFilters = [] as { key: string | keyof T | undefined, value: object }[];
                //     for (const filter of appliedFilters) {
                //         if (filter.column.customFilter) {
                //             constructedFilters.push({ key: filter.column.field, value: await filter.column.customFilter(filter) }); 
                //         } else {
                //             const searchParams = {} as any;
                //             if (filter.column.type === 'boolean') {
                //                 searchParams.eq = filter.filterText.toLowerCase();
                //             }
                //             else {
                //                 searchParams.wildcard = '*' + filter.filterText.toLowerCase() + '*';
                //             }
                //             constructedFilters.push({
                //                 key: filter.column.field,
                //                 value: {
                //                     [filter.column.field as string]: {
                //                         ...searchParams
                //                     }
                //                 }
                //             });
                //         }
                //     }

                //     if (props.onFilter) {
                //         const v = constructedFilters.map(cf => cf.value);
                //         props.onFilter(v.length > 0 ? { and: v } as F : {} as F);
                //     }
                //     if (props.onConditionalFilter) {
                //         const isExcludedField = (currentField: string, appliedFilterOptions?: AppliedFilterOptions,) => {
                //             if (appliedFilterOptions?.excludedFields) {
                //                 return appliedFilterOptions.excludedFields.indexOf(currentField) >= 0;
                //             }
                //             return false;
                //         }
                //         props.onConditionalFilter((options) => {
                //             const v = constructedFilters
                //                 .map(cf =>
                //                     // If this field should be excluded
                //                     !isExcludedField(cf.key as string, options)
                //                         // return the custom filter
                //                         ? cf.value :
                //                         // otherwise don't match
                //                         { and: [] }
                //                 );
                //             return v.length > 0 ? { and: v } as F : {} as F;
                //         });
                //     }
                // } else {
                //     if (props.onFilter) {
                //         props.onFilter();
                //     }
                //     if (props.onConditionalFilter) {
                //         props.onConditionalFilter();
                //     }
                // }
            }
        }
        constructFilters();
    }, [appliedFilters]);

    const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(false);
    const handleAdvancedFilterToggle = () => {
        setShowAdvancedFilter(!showAdvancedFilter);
    }

    const isFieldSearchable = (col: CustomColumn<T>) => {
        // Nested properties (property names with ".") can't be filtered on
        // so don't let those properties be selected
        return col.advancedFilter !== false && (col.field?.toString().indexOf('.') || -1) < 0;
    }

    return (
        <Fragment>
            <Box mt={1} mb={1}>
                <Grid container>
                    <Grid item xs={1}>
                        <IconButton size="small" color="primary" onClick={handleAdvancedFilterToggle}>
                            <FilterListIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={11}>
                        <Grow
                            in={showAdvancedFilter}
                            style={{ transformOrigin: '0 0 0' }}
                            {...(showAdvancedFilter ? { timeout: 100 } : {})}
                        >
                            {showAdvancedFilter ?
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <Select
                                                    fullWidth
                                                    onChange={handleSelectedSearchFieldChange}
                                                    value={selectedSearchField}
                                                >
                                                    {props.columns.map(col => (
                                                        isFieldSearchable(col) && <MenuItem value={col.field as string | undefined}>{col.title}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    label="contains text"
                                                    value={filterText}
                                                    onChange={handleFilterTextChange}
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={filterText.length < 1}
                                                    onClick={handleApplyFilter}
                                                >
                                                    Apply
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box className={classes.chipContainer} mt={1} mb={1}>
                                            {appliedFilters.map((filter, index) => (
                                                <Chip
                                                    label={`${filter.column.title} contains "${filter.filterText}"`}
                                                    size="small"
                                                    onClick={() => handleAppliedFilterClick(filter)}
                                                    onDelete={() => handleDelete(index)}
                                                    color="primary"
                                                />
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                                :
                                <div />
                            }
                        </Grow>
                    </Grid>
                </Grid>
            </Box>
        </Fragment>
    );

}

export default GenericFilter;