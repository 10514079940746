import { makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from "screenfull";
import Controls from './Control';
import VideoPlayerProps from './VideoPlayerProps';

const useStyles = makeStyles((theme) => ({
    playerWrapper: {
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%',
            width: '640px'
        },
        position: "relative",
    },
    reactPlayer: {
        [theme.breakpoints.down('xs')]: {
            width: '100% !important'
        },
        [theme.breakpoints.up('sm')]: {
            maxWidth: '100%'
        },
        height: 'initial !important',
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }
}));


export default function VideoPlayer(props: VideoPlayerProps) {

    const classes = useStyles();
    const controlsRef = useRef<HTMLDivElement | null>(null);
    const playerContainerRef = useRef<HTMLDivElement | null>(null);

    const handleMouseMove = () => {
        if (controlsRef.current) {
            controlsRef.current.style.visibility = "visible";
        }
        //count = 0;
    };
    // only hide control if the play is not playing 
    const hanldeMouseLeave = () => {
        if (controlsRef && controlsRef?.current) {
            controlsRef.current.style.visibility = "hidden";
        }
    };

    const toggleFullScreen = () => {
        if (playerContainerRef.current && playerRef.current && playerRef.current) {
            screenfull.toggle(playerContainerRef.current);
        }
    };

    const playerRef = useRef<ReactPlayer | null>(null);
    const [state, setState] = useState({
        pip: false,
        playing: false,
        controls: false,
        light: false,
        muted: false,
        played: 0,
        duration: 0,
        playbackRate: 1.0,
        volume: 1,
        loop: false,
        seeking: false,
    });

    const handlePlayPause = () => {
        setState({ ...state, playing: !state.playing });
    };

    const {
        playing,
        controls,
        light,
        muted,
        loop,
        playbackRate,
        pip,
        played,
        seeking,
        volume,
    } = state;

    const handleMute = () => {
        setState({ ...state, muted: !state.muted });
    }

    const handleVolumeChange = (event: any, newValue: any): void => {
        setState({
            ...state,
            volume: newValue / 100,
            muted: newValue === 0 ? true : false,
        })
        console.log(newValue / 100)
    }

    return (
        <div className={classes.playerWrapper}
            onMouseMove={handleMouseMove}
            onMouseLeave={hanldeMouseLeave}
            ref={playerContainerRef}
        >
            <ReactPlayer
                ref={playerRef}
                className={classes.reactPlayer}
                url={props.url}
                controls={false}
                playing={playing}
                volume={state.volume}
                width="100%"
                progressInterval={120000}
                onReady={() => props.onReady(playerRef)}
                onProgress={() => props.onSaveState(playerRef)}
                onPause={() => props.onSaveState(playerRef)}
                onEnded={() => props.onSaveState(playerRef)}
                onSeek={(seconds: number) => props.onSeek(playerRef, seconds)}
            />
            <Controls volume={volume} onVolumeChange={handleVolumeChange} onVolumeSeekDown={handleVolumeChange}  ref={controlsRef} onRewind={function (): void {

            }} onPlayPause={handlePlayPause} onToggleFullScreen={toggleFullScreen} playing={playing} played={0} elapsedTime="10" totalDuration="10" muted={false} playbackRate={0}></Controls>
        </div>
    );
}