/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getResource = /* GraphQL */ `
  query GetResource($id: ID!) {
    getResource(id: $id) {
      id
      type
      title
      description
      resourcePath
      thumbnailPath
      relevance
      tags
      channelName
      owner
      videoSequence
      appAsString
      totalMins
      createdAt
      updatedAt
    }
  }
`;
export const listResources = /* GraphQL */ `
  query ListResources(
    $filter: ModelResourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listResources(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        title
        description
        resourcePath
        thumbnailPath
        relevance
        tags
        channelName
        owner
        videoSequence
        appAsString
        totalMins
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTest1 = /* GraphQL */ `
  query GetTest1($id: ID!) {
    getTest1(id: $id) {
      id
      name1
      createdAt
      updatedAt
    }
  }
`;
export const listTest1s = /* GraphQL */ `
  query ListTest1s(
    $filter: ModelTest1FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTest1s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name1
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTest = /* GraphQL */ `
  query GetTest($id: ID!) {
    getTest(id: $id) {
      id
      name1
      createdAt
      updatedAt
    }
  }
`;
export const listTests = /* GraphQL */ `
  query ListTests(
    $filter: ModelTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name1
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserInfo = /* GraphQL */ `
  query GetUserInfo($id: ID!) {
    getUserInfo(id: $id) {
      id
      videos {
        videoId
        location
        progress
        videoSequence
        progressInMins
        name
      }
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listUserInfos = /* GraphQL */ `
  query ListUserInfos(
    $filter: ModelUserInfoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInfos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        videos {
          videoId
          location
          progress
          videoSequence
          progressInMins
          name
        }
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getApplication = /* GraphQL */ `
  query GetApplication($id: ID!) {
    getApplication(id: $id) {
      id
      appName
      landingPages {
        id
        appID
        title
        url
        createdAt
        updatedAt
      }
      menuVisible
      createdAt
      updatedAt
    }
  }
`;
export const listApplications = /* GraphQL */ `
  query ListApplications(
    $filter: ModelApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appName
        landingPages {
          id
          appID
          title
          url
          createdAt
          updatedAt
        }
        menuVisible
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAppLandingPage = /* GraphQL */ `
  query GetAppLandingPage($id: ID!) {
    getAppLandingPage(id: $id) {
      id
      appID
      title
      url
      createdAt
      updatedAt
    }
  }
`;
export const listAppLandingPages = /* GraphQL */ `
  query ListAppLandingPages(
    $filter: ModelAppLandingPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppLandingPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        appID
        title
        url
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getENEOCompletionAlert = /* GraphQL */ `
  query GetENEOCompletionAlert($id: ID!) {
    getENEOCompletionAlert(id: $id) {
      id
      lastNotice
      createdAt
      updatedAt
    }
  }
`;
export const listENEOCompletionAlerts = /* GraphQL */ `
  query ListENEOCompletionAlerts(
    $filter: ModeleNEOCompletionAlertFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listENEOCompletionAlerts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastNotice
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchResources = /* GraphQL */ `
  query SearchResources(
    $filter: SearchableResourceFilterInput
    $sort: SearchableResourceSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchResources(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        type
        title
        description
        resourcePath
        thumbnailPath
        relevance
        tags
        channelName
        owner
        videoSequence
        appAsString
        totalMins
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchApplications = /* GraphQL */ `
  query SearchApplications(
    $filter: SearchableApplicationFilterInput
    $sort: SearchableApplicationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchApplications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        appName
        landingPages {
          id
          appID
          title
          url
          createdAt
          updatedAt
        }
        menuVisible
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const searchENEOCompletionAlerts = /* GraphQL */ `
  query SearchENEOCompletionAlerts(
    $filter: SearchableeNEOCompletionAlertFilterInput
    $sort: SearchableeNEOCompletionAlertSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchENEOCompletionAlerts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        lastNotice
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
