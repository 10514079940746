/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "dev-eneohosting-eneoprod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2oloyhkq06tqn.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:2d10c5c4-a19a-405e-b39d-336661da208c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_at1Q7ijCI",
    "aws_user_pools_web_client_id": "7d1qah3bfa397f9li9hh6do923",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "eneoc7ec5de62605436a85a8cd85c2083d1040824-eneoprod",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://eyb6o7iovrfyxmqljcssiomibu.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://9up3mfzk9e.execute-api.us-east-1.amazonaws.com/eneoprod",
            "region": "us-east-1"
        }
    ],
    "aws_mobile_analytics_app_id": "6b980a0eb9fc41d1b6799f89566c0331",
    "aws_mobile_analytics_app_region": "us-east-1"
};


export default awsmobile;
