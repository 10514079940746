/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateResourceInput = {
  id?: string | null,
  type: string,
  title: string,
  description?: string | null,
  resourcePath?: string | null,
  thumbnailPath?: string | null,
  relevance?: number | null,
  tags?: Array< string > | null,
  channelName?: string | null,
  owner?: string | null,
  videoSequence?: number | null,
  appAsString?: string | null,
  totalMins?: number | null,
};

export type ModelResourceConditionInput = {
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  resourcePath?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  relevance?: ModelIntInput | null,
  tags?: ModelStringInput | null,
  channelName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  videoSequence?: ModelIntInput | null,
  appAsString?: ModelStringInput | null,
  totalMins?: ModelFloatInput | null,
  and?: Array< ModelResourceConditionInput | null > | null,
  or?: Array< ModelResourceConditionInput | null > | null,
  not?: ModelResourceConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Resource = {
  __typename: "Resource",
  id: string,
  type: string,
  title: string,
  description?: string | null,
  resourcePath?: string | null,
  thumbnailPath?: string | null,
  relevance?: number | null,
  tags?: Array< string > | null,
  channelName?: string | null,
  owner?: string | null,
  videoSequence?: number | null,
  appAsString?: string | null,
  totalMins?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateResourceInput = {
  id: string,
  type?: string | null,
  title?: string | null,
  description?: string | null,
  resourcePath?: string | null,
  thumbnailPath?: string | null,
  relevance?: number | null,
  tags?: Array< string > | null,
  channelName?: string | null,
  owner?: string | null,
  videoSequence?: number | null,
  appAsString?: string | null,
  totalMins?: number | null,
};

export type DeleteResourceInput = {
  id: string,
};

export type CreateTest1Input = {
  id?: string | null,
  name1?: string | null,
};

export type ModelTest1ConditionInput = {
  name1?: ModelStringInput | null,
  and?: Array< ModelTest1ConditionInput | null > | null,
  or?: Array< ModelTest1ConditionInput | null > | null,
  not?: ModelTest1ConditionInput | null,
};

export type Test1 = {
  __typename: "Test1",
  id: string,
  name1?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTest1Input = {
  id: string,
  name1?: string | null,
};

export type DeleteTest1Input = {
  id: string,
};

export type CreateTestInput = {
  id?: string | null,
  name1?: string | null,
};

export type ModelTestConditionInput = {
  name1?: ModelStringInput | null,
  and?: Array< ModelTestConditionInput | null > | null,
  or?: Array< ModelTestConditionInput | null > | null,
  not?: ModelTestConditionInput | null,
};

export type Test = {
  __typename: "Test",
  id: string,
  name1?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateTestInput = {
  id: string,
  name1?: string | null,
};

export type DeleteTestInput = {
  id: string,
};

export type CreateUserInfoInput = {
  id?: string | null,
  videos: Array< VideoProgressInput >,
  owner?: string | null,
};

export type VideoProgressInput = {
  videoId: string,
  location: number,
  progress: number,
  videoSequence?: number | null,
  progressInMins?: number | null,
  name?: string | null,
};

export type ModelUserInfoConditionInput = {
  and?: Array< ModelUserInfoConditionInput | null > | null,
  or?: Array< ModelUserInfoConditionInput | null > | null,
  not?: ModelUserInfoConditionInput | null,
};

export type UserInfo = {
  __typename: "UserInfo",
  id: string,
  videos:  Array<VideoProgress >,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type VideoProgress = {
  __typename: "VideoProgress",
  videoId: string,
  location: number,
  progress: number,
  videoSequence?: number | null,
  progressInMins?: number | null,
  name?: string | null,
};

export type UpdateUserInfoInput = {
  id: string,
  videos?: Array< VideoProgressInput > | null,
  owner?: string | null,
};

export type DeleteUserInfoInput = {
  id: string,
};

export type CreateResourceAccessInput = {
  id?: string | null,
  userID: string,
  resourceID: string,
  owner?: string | null,
};

export type ModelResourceAccessConditionInput = {
  userID?: ModelIDInput | null,
  resourceID?: ModelIDInput | null,
  and?: Array< ModelResourceAccessConditionInput | null > | null,
  or?: Array< ModelResourceAccessConditionInput | null > | null,
  not?: ModelResourceAccessConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ResourceAccess = {
  __typename: "ResourceAccess",
  id: string,
  userID: string,
  resourceID: string,
  user: UserInfo,
  resource: Resource,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateResourceAccessInput = {
  id: string,
  userID?: string | null,
  resourceID?: string | null,
  owner?: string | null,
};

export type DeleteResourceAccessInput = {
  id: string,
};

export type CreateApplicationInput = {
  id: string,
  appName: string,
  menuVisible: boolean,
};

export type ModelApplicationConditionInput = {
  appName?: ModelStringInput | null,
  menuVisible?: ModelBooleanInput | null,
  and?: Array< ModelApplicationConditionInput | null > | null,
  or?: Array< ModelApplicationConditionInput | null > | null,
  not?: ModelApplicationConditionInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Application = {
  __typename: "Application",
  id: string,
  appName: string,
  landingPages?:  Array<AppLandingPage | null > | null,
  menuVisible: boolean,
  createdAt: string,
  updatedAt: string,
};

export type AppLandingPage = {
  __typename: "AppLandingPage",
  id: string,
  appID: string,
  title: string,
  url: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateApplicationInput = {
  id: string,
  appName?: string | null,
  menuVisible?: boolean | null,
};

export type DeleteApplicationInput = {
  id: string,
};

export type CreateAppLandingPageInput = {
  id?: string | null,
  appID: string,
  title: string,
  url: string,
};

export type ModelAppLandingPageConditionInput = {
  appID?: ModelStringInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelAppLandingPageConditionInput | null > | null,
  or?: Array< ModelAppLandingPageConditionInput | null > | null,
  not?: ModelAppLandingPageConditionInput | null,
};

export type UpdateAppLandingPageInput = {
  id: string,
  appID?: string | null,
  title?: string | null,
  url?: string | null,
};

export type DeleteAppLandingPageInput = {
  id: string,
};

export type CreateENEOCompletionAlertInput = {
  id?: string | null,
  lastNotice?: number | null,
};

export type ModeleNEOCompletionAlertConditionInput = {
  lastNotice?: ModelIntInput | null,
  and?: Array< ModeleNEOCompletionAlertConditionInput | null > | null,
  or?: Array< ModeleNEOCompletionAlertConditionInput | null > | null,
  not?: ModeleNEOCompletionAlertConditionInput | null,
};

export type eNEOCompletionAlert = {
  __typename: "eNEOCompletionAlert",
  id: string,
  lastNotice?: number | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateENEOCompletionAlertInput = {
  id: string,
  lastNotice?: number | null,
};

export type DeleteENEOCompletionAlertInput = {
  id: string,
};

export type ModelResourceFilterInput = {
  id?: ModelIDInput | null,
  type?: ModelStringInput | null,
  title?: ModelStringInput | null,
  description?: ModelStringInput | null,
  resourcePath?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  relevance?: ModelIntInput | null,
  tags?: ModelStringInput | null,
  channelName?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  videoSequence?: ModelIntInput | null,
  appAsString?: ModelStringInput | null,
  totalMins?: ModelFloatInput | null,
  and?: Array< ModelResourceFilterInput | null > | null,
  or?: Array< ModelResourceFilterInput | null > | null,
  not?: ModelResourceFilterInput | null,
};

export type ModelResourceConnection = {
  __typename: "ModelResourceConnection",
  items:  Array<Resource | null >,
  nextToken?: string | null,
};

export type ModelTest1FilterInput = {
  name1?: ModelStringInput | null,
  and?: Array< ModelTest1FilterInput | null > | null,
  or?: Array< ModelTest1FilterInput | null > | null,
  not?: ModelTest1FilterInput | null,
};

export type ModelTest1Connection = {
  __typename: "ModelTest1Connection",
  items:  Array<Test1 | null >,
  nextToken?: string | null,
};

export type ModelTestFilterInput = {
  name1?: ModelStringInput | null,
  and?: Array< ModelTestFilterInput | null > | null,
  or?: Array< ModelTestFilterInput | null > | null,
  not?: ModelTestFilterInput | null,
};

export type ModelTestConnection = {
  __typename: "ModelTestConnection",
  items:  Array<Test | null >,
  nextToken?: string | null,
};

export type ModelUserInfoFilterInput = {
  id?: ModelIDInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserInfoFilterInput | null > | null,
  or?: Array< ModelUserInfoFilterInput | null > | null,
  not?: ModelUserInfoFilterInput | null,
};

export type ModelUserInfoConnection = {
  __typename: "ModelUserInfoConnection",
  items:  Array<UserInfo | null >,
  nextToken?: string | null,
};

export type ModelApplicationFilterInput = {
  id?: ModelStringInput | null,
  appName?: ModelStringInput | null,
  menuVisible?: ModelBooleanInput | null,
  and?: Array< ModelApplicationFilterInput | null > | null,
  or?: Array< ModelApplicationFilterInput | null > | null,
  not?: ModelApplicationFilterInput | null,
};

export type ModelApplicationConnection = {
  __typename: "ModelApplicationConnection",
  items:  Array<Application | null >,
  nextToken?: string | null,
};

export type ModelAppLandingPageFilterInput = {
  appID?: ModelStringInput | null,
  title?: ModelStringInput | null,
  url?: ModelStringInput | null,
  and?: Array< ModelAppLandingPageFilterInput | null > | null,
  or?: Array< ModelAppLandingPageFilterInput | null > | null,
  not?: ModelAppLandingPageFilterInput | null,
};

export type ModelAppLandingPageConnection = {
  __typename: "ModelAppLandingPageConnection",
  items:  Array<AppLandingPage | null >,
  nextToken?: string | null,
};

export type ModeleNEOCompletionAlertFilterInput = {
  id?: ModelIDInput | null,
  lastNotice?: ModelIntInput | null,
  and?: Array< ModeleNEOCompletionAlertFilterInput | null > | null,
  or?: Array< ModeleNEOCompletionAlertFilterInput | null > | null,
  not?: ModeleNEOCompletionAlertFilterInput | null,
};

export type ModeleNEOCompletionAlertConnection = {
  __typename: "ModeleNEOCompletionAlertConnection",
  items:  Array<eNEOCompletionAlert | null >,
  nextToken?: string | null,
};

export type SearchableResourceFilterInput = {
  id?: SearchableIDFilterInput | null,
  type?: SearchableStringFilterInput | null,
  title?: SearchableStringFilterInput | null,
  description?: SearchableStringFilterInput | null,
  resourcePath?: SearchableStringFilterInput | null,
  thumbnailPath?: SearchableStringFilterInput | null,
  relevance?: SearchableIntFilterInput | null,
  tags?: SearchableStringFilterInput | null,
  channelName?: SearchableStringFilterInput | null,
  owner?: SearchableStringFilterInput | null,
  videoSequence?: SearchableIntFilterInput | null,
  appAsString?: SearchableStringFilterInput | null,
  totalMins?: SearchableFloatFilterInput | null,
  and?: Array< SearchableResourceFilterInput | null > | null,
  or?: Array< SearchableResourceFilterInput | null > | null,
  not?: SearchableResourceFilterInput | null,
};

export type SearchableIDFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableStringFilterInput = {
  ne?: string | null,
  gt?: string | null,
  lt?: string | null,
  gte?: string | null,
  lte?: string | null,
  eq?: string | null,
  match?: string | null,
  matchPhrase?: string | null,
  matchPhrasePrefix?: string | null,
  multiMatch?: string | null,
  exists?: boolean | null,
  wildcard?: string | null,
  regexp?: string | null,
  range?: Array< string | null > | null,
};

export type SearchableIntFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableFloatFilterInput = {
  ne?: number | null,
  gt?: number | null,
  lt?: number | null,
  gte?: number | null,
  lte?: number | null,
  eq?: number | null,
  range?: Array< number | null > | null,
};

export type SearchableResourceSortInput = {
  field?: SearchableResourceSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableResourceSortableFields {
  id = "id",
  type = "type",
  title = "title",
  description = "description",
  resourcePath = "resourcePath",
  thumbnailPath = "thumbnailPath",
  relevance = "relevance",
  tags = "tags",
  channelName = "channelName",
  owner = "owner",
  videoSequence = "videoSequence",
  appAsString = "appAsString",
  totalMins = "totalMins",
}


export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}


export type SearchableResourceConnection = {
  __typename: "SearchableResourceConnection",
  items:  Array<Resource | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableApplicationFilterInput = {
  id?: SearchableStringFilterInput | null,
  appName?: SearchableStringFilterInput | null,
  menuVisible?: SearchableBooleanFilterInput | null,
  and?: Array< SearchableApplicationFilterInput | null > | null,
  or?: Array< SearchableApplicationFilterInput | null > | null,
  not?: SearchableApplicationFilterInput | null,
};

export type SearchableBooleanFilterInput = {
  eq?: boolean | null,
  ne?: boolean | null,
};

export type SearchableApplicationSortInput = {
  field?: SearchableApplicationSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableApplicationSortableFields {
  id = "id",
  appName = "appName",
  menuVisible = "menuVisible",
}


export type SearchableApplicationConnection = {
  __typename: "SearchableApplicationConnection",
  items:  Array<Application | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type SearchableeNEOCompletionAlertFilterInput = {
  id?: SearchableIDFilterInput | null,
  lastNotice?: SearchableIntFilterInput | null,
  and?: Array< SearchableeNEOCompletionAlertFilterInput | null > | null,
  or?: Array< SearchableeNEOCompletionAlertFilterInput | null > | null,
  not?: SearchableeNEOCompletionAlertFilterInput | null,
};

export type SearchableeNEOCompletionAlertSortInput = {
  field?: SearchableeNEOCompletionAlertSortableFields | null,
  direction?: SearchableSortDirection | null,
};

export enum SearchableeNEOCompletionAlertSortableFields {
  id = "id",
  lastNotice = "lastNotice",
}


export type SearchableeNEOCompletionAlertConnection = {
  __typename: "SearchableeNEOCompletionAlertConnection",
  items:  Array<eNEOCompletionAlert | null >,
  nextToken?: string | null,
  total?: number | null,
};

export type CreateResourceMutationVariables = {
  input: CreateResourceInput,
  condition?: ModelResourceConditionInput | null,
};

export type CreateResourceMutation = {
  createResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateResourceMutationVariables = {
  input: UpdateResourceInput,
  condition?: ModelResourceConditionInput | null,
};

export type UpdateResourceMutation = {
  updateResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteResourceMutationVariables = {
  input: DeleteResourceInput,
  condition?: ModelResourceConditionInput | null,
};

export type DeleteResourceMutation = {
  deleteResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTest1MutationVariables = {
  input: CreateTest1Input,
  condition?: ModelTest1ConditionInput | null,
};

export type CreateTest1Mutation = {
  createTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTest1MutationVariables = {
  input: UpdateTest1Input,
  condition?: ModelTest1ConditionInput | null,
};

export type UpdateTest1Mutation = {
  updateTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTest1MutationVariables = {
  input: DeleteTest1Input,
  condition?: ModelTest1ConditionInput | null,
};

export type DeleteTest1Mutation = {
  deleteTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTestMutationVariables = {
  input: CreateTestInput,
  condition?: ModelTestConditionInput | null,
};

export type CreateTestMutation = {
  createTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTestMutationVariables = {
  input: UpdateTestInput,
  condition?: ModelTestConditionInput | null,
};

export type UpdateTestMutation = {
  updateTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTestMutationVariables = {
  input: DeleteTestInput,
  condition?: ModelTestConditionInput | null,
};

export type DeleteTestMutation = {
  deleteTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserInfoMutationVariables = {
  input: CreateUserInfoInput,
  condition?: ModelUserInfoConditionInput | null,
};

export type CreateUserInfoMutation = {
  createUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserInfoMutationVariables = {
  input: UpdateUserInfoInput,
  condition?: ModelUserInfoConditionInput | null,
};

export type UpdateUserInfoMutation = {
  updateUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserInfoMutationVariables = {
  input: DeleteUserInfoInput,
  condition?: ModelUserInfoConditionInput | null,
};

export type DeleteUserInfoMutation = {
  deleteUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateResourceAccessMutationVariables = {
  input: CreateResourceAccessInput,
  condition?: ModelResourceAccessConditionInput | null,
};

export type CreateResourceAccessMutation = {
  createResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateResourceAccessMutationVariables = {
  input: UpdateResourceAccessInput,
  condition?: ModelResourceAccessConditionInput | null,
};

export type UpdateResourceAccessMutation = {
  updateResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteResourceAccessMutationVariables = {
  input: DeleteResourceAccessInput,
  condition?: ModelResourceAccessConditionInput | null,
};

export type DeleteResourceAccessMutation = {
  deleteResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateApplicationMutationVariables = {
  input: CreateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type CreateApplicationMutation = {
  createApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateApplicationMutationVariables = {
  input: UpdateApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type UpdateApplicationMutation = {
  updateApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteApplicationMutationVariables = {
  input: DeleteApplicationInput,
  condition?: ModelApplicationConditionInput | null,
};

export type DeleteApplicationMutation = {
  deleteApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateAppLandingPageMutationVariables = {
  input: CreateAppLandingPageInput,
  condition?: ModelAppLandingPageConditionInput | null,
};

export type CreateAppLandingPageMutation = {
  createAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateAppLandingPageMutationVariables = {
  input: UpdateAppLandingPageInput,
  condition?: ModelAppLandingPageConditionInput | null,
};

export type UpdateAppLandingPageMutation = {
  updateAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteAppLandingPageMutationVariables = {
  input: DeleteAppLandingPageInput,
  condition?: ModelAppLandingPageConditionInput | null,
};

export type DeleteAppLandingPageMutation = {
  deleteAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateENEOCompletionAlertMutationVariables = {
  input: CreateENEOCompletionAlertInput,
  condition?: ModeleNEOCompletionAlertConditionInput | null,
};

export type CreateENEOCompletionAlertMutation = {
  createENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateENEOCompletionAlertMutationVariables = {
  input: UpdateENEOCompletionAlertInput,
  condition?: ModeleNEOCompletionAlertConditionInput | null,
};

export type UpdateENEOCompletionAlertMutation = {
  updateENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteENEOCompletionAlertMutationVariables = {
  input: DeleteENEOCompletionAlertInput,
  condition?: ModeleNEOCompletionAlertConditionInput | null,
};

export type DeleteENEOCompletionAlertMutation = {
  deleteENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetResourceQueryVariables = {
  id: string,
};

export type GetResourceQuery = {
  getResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListResourcesQueryVariables = {
  filter?: ModelResourceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListResourcesQuery = {
  listResources?:  {
    __typename: "ModelResourceConnection",
    items:  Array< {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTest1QueryVariables = {
  id: string,
};

export type GetTest1Query = {
  getTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTest1sQueryVariables = {
  filter?: ModelTest1FilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTest1sQuery = {
  listTest1s?:  {
    __typename: "ModelTest1Connection",
    items:  Array< {
      __typename: "Test1",
      id: string,
      name1?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTestQueryVariables = {
  id: string,
};

export type GetTestQuery = {
  getTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTestsQueryVariables = {
  filter?: ModelTestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTestsQuery = {
  listTests?:  {
    __typename: "ModelTestConnection",
    items:  Array< {
      __typename: "Test",
      id: string,
      name1?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserInfoQueryVariables = {
  id: string,
};

export type GetUserInfoQuery = {
  getUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserInfosQueryVariables = {
  filter?: ModelUserInfoFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserInfosQuery = {
  listUserInfos?:  {
    __typename: "ModelUserInfoConnection",
    items:  Array< {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetApplicationQueryVariables = {
  id: string,
};

export type GetApplicationQuery = {
  getApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListApplicationsQueryVariables = {
  filter?: ModelApplicationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListApplicationsQuery = {
  listApplications?:  {
    __typename: "ModelApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      appName: string,
      landingPages?:  Array< {
        __typename: "AppLandingPage",
        id: string,
        appID: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      menuVisible: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetAppLandingPageQueryVariables = {
  id: string,
};

export type GetAppLandingPageQuery = {
  getAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListAppLandingPagesQueryVariables = {
  filter?: ModelAppLandingPageFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAppLandingPagesQuery = {
  listAppLandingPages?:  {
    __typename: "ModelAppLandingPageConnection",
    items:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetENEOCompletionAlertQueryVariables = {
  id: string,
};

export type GetENEOCompletionAlertQuery = {
  getENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListENEOCompletionAlertsQueryVariables = {
  filter?: ModeleNEOCompletionAlertFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListENEOCompletionAlertsQuery = {
  listENEOCompletionAlerts?:  {
    __typename: "ModeleNEOCompletionAlertConnection",
    items:  Array< {
      __typename: "eNEOCompletionAlert",
      id: string,
      lastNotice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SearchResourcesQueryVariables = {
  filter?: SearchableResourceFilterInput | null,
  sort?: SearchableResourceSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchResourcesQuery = {
  searchResources?:  {
    __typename: "SearchableResourceConnection",
    items:  Array< {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchApplicationsQueryVariables = {
  filter?: SearchableApplicationFilterInput | null,
  sort?: SearchableApplicationSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchApplicationsQuery = {
  searchApplications?:  {
    __typename: "SearchableApplicationConnection",
    items:  Array< {
      __typename: "Application",
      id: string,
      appName: string,
      landingPages?:  Array< {
        __typename: "AppLandingPage",
        id: string,
        appID: string,
        title: string,
        url: string,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      menuVisible: boolean,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type SearchENEOCompletionAlertsQueryVariables = {
  filter?: SearchableeNEOCompletionAlertFilterInput | null,
  sort?: SearchableeNEOCompletionAlertSortInput | null,
  limit?: number | null,
  nextToken?: string | null,
  from?: number | null,
};

export type SearchENEOCompletionAlertsQuery = {
  searchENEOCompletionAlerts?:  {
    __typename: "SearchableeNEOCompletionAlertConnection",
    items:  Array< {
      __typename: "eNEOCompletionAlert",
      id: string,
      lastNotice?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
    total?: number | null,
  } | null,
};

export type OnCreateResourceSubscription = {
  onCreateResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateResourceSubscription = {
  onUpdateResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteResourceSubscription = {
  onDeleteResource?:  {
    __typename: "Resource",
    id: string,
    type: string,
    title: string,
    description?: string | null,
    resourcePath?: string | null,
    thumbnailPath?: string | null,
    relevance?: number | null,
    tags?: Array< string > | null,
    channelName?: string | null,
    owner?: string | null,
    videoSequence?: number | null,
    appAsString?: string | null,
    totalMins?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTest1Subscription = {
  onCreateTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTest1Subscription = {
  onUpdateTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTest1Subscription = {
  onDeleteTest1?:  {
    __typename: "Test1",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTestSubscription = {
  onCreateTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTestSubscription = {
  onUpdateTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTestSubscription = {
  onDeleteTest?:  {
    __typename: "Test",
    id: string,
    name1?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserInfoSubscription = {
  onCreateUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserInfoSubscription = {
  onUpdateUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserInfoSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserInfoSubscription = {
  onDeleteUserInfo?:  {
    __typename: "UserInfo",
    id: string,
    videos:  Array< {
      __typename: "VideoProgress",
      videoId: string,
      location: number,
      progress: number,
      videoSequence?: number | null,
      progressInMins?: number | null,
      name?: string | null,
    } >,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateResourceAccessSubscriptionVariables = {
  owner: string,
};

export type OnCreateResourceAccessSubscription = {
  onCreateResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateResourceAccessSubscriptionVariables = {
  owner: string,
};

export type OnUpdateResourceAccessSubscription = {
  onUpdateResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteResourceAccessSubscriptionVariables = {
  owner: string,
};

export type OnDeleteResourceAccessSubscription = {
  onDeleteResourceAccess?:  {
    __typename: "ResourceAccess",
    id: string,
    userID: string,
    resourceID: string,
    user:  {
      __typename: "UserInfo",
      id: string,
      videos:  Array< {
        __typename: "VideoProgress",
        videoId: string,
        location: number,
        progress: number,
        videoSequence?: number | null,
        progressInMins?: number | null,
        name?: string | null,
      } >,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
    },
    resource:  {
      __typename: "Resource",
      id: string,
      type: string,
      title: string,
      description?: string | null,
      resourcePath?: string | null,
      thumbnailPath?: string | null,
      relevance?: number | null,
      tags?: Array< string > | null,
      channelName?: string | null,
      owner?: string | null,
      videoSequence?: number | null,
      appAsString?: string | null,
      totalMins?: number | null,
      createdAt: string,
      updatedAt: string,
    },
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateApplicationSubscription = {
  onCreateApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateApplicationSubscription = {
  onUpdateApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteApplicationSubscription = {
  onDeleteApplication?:  {
    __typename: "Application",
    id: string,
    appName: string,
    landingPages?:  Array< {
      __typename: "AppLandingPage",
      id: string,
      appID: string,
      title: string,
      url: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    menuVisible: boolean,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateAppLandingPageSubscription = {
  onCreateAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateAppLandingPageSubscription = {
  onUpdateAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteAppLandingPageSubscription = {
  onDeleteAppLandingPage?:  {
    __typename: "AppLandingPage",
    id: string,
    appID: string,
    title: string,
    url: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateENEOCompletionAlertSubscription = {
  onCreateENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateENEOCompletionAlertSubscription = {
  onUpdateENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteENEOCompletionAlertSubscription = {
  onDeleteENEOCompletionAlert?:  {
    __typename: "eNEOCompletionAlert",
    id: string,
    lastNotice?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};
