import { Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core';
import {PlayArrow, Lock} from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { VideoProgressInput } from '../../../API';
import ResourceService from '../ResourceService';
import ResourceThumbnailProps from './ResourceThumbnailProps';
import VideoService from './Video/VideoService';
import Amplify, {Analytics} from 'aws-amplify';
import Title from '../../Introduction/Title';
import VideoPage from './Video/VideoPage';

const DESCRIPTION_LINES = 3;

const scrollToRef = (ref: any) => window.scrollTo(0, ref?.current?.offsetTop)   
// General scroll to element function


export default function ResourceThumbnail(props: ResourceThumbnailProps) {

    const useStyles = makeStyles((theme) => ({
        root: {
            [theme.breakpoints.up('sm')]: {
                width: 250,
                marginRight: 75
            }
        },
        icon: {
            borderRadius: '20px',
            borderStyle: 'solid',
            borderColor: 'lightgrey',
            backgroundColor: 'lightgrey',
            color: 'white',
            opacity: '100%',
            fontSize: '4em',
            zIndex: 1
        },
        playerWrapper: {
            position: 'relative',
            paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */
        },
        thumbnail: {
            position: 'absolute',
            top: 0,
            left: 0,
            border: 'solid rgb(123, 123, 123) 1px',
            backgroundColor: 'black',
            height: '100%',
            width: '100%'
        },
        description: {
            paddingTop: 5,
            height: DESCRIPTION_LINES * 23,
            overflow: 'hidden',
            position: 'relative',
            "&::after": {
                content: "''",
                textAlign: 'right',
                position: 'absolute',
                bottom: '5px',
                right: 0,
                width: '50%',
                height: '1.2em',
                background: 'linear-gradient(to right, rgba(230, 230, 230, 0), rgba(230, 230, 230, 1) 50%)'
            }
        },
        shareIcon: {
            marginLeft: 'auto'
        },
        playArrowContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%'
        },
        image: {
            width: props.resource.type === 'video' ? '100%' : '150px',
            height: props.resource.type === 'video' ? '100%' : undefined,
            position: props.resource.type === 'video' ? 'absolute' : undefined,
            top: 0
        },
        progressBar: {
            height: '4%',
            backgroundColor: 'red',
            position: 'absolute',
            bottom: 0
        },
        viewedIcon: {
            zIndex: 1,
            width: '15%',
            height: '22%',
            backgroundColor: 'red',
            color: 'white',
            position: 'absolute',
            right: 0,
            top: 0
        },
        descriptionItem: {
            width: '90%'
        },
        iconItem: {
            width: '10%'
        },
        arrowCursor:{
            cursor: 'default',
        }
    }));

    const classes = useStyles();

    const playNext = useRef<HTMLInputElement | null>(null)
    const allOtherVideos = useRef(null);


    function handleBackClick() {
        console.log('in handleBackClick');
        playNext?.current?.scrollIntoView({ behavior: 'smooth' })
    }

    handleBackClick()


    /**
     * Get the presigned thumbnail URL
     */
    const [thumbnailURL, setThumbnailURL] = useState<string>();
    const  [lockState, setLockState] = useState<boolean>(false);
    useEffect(() => {
        if (props.resource.thumbnailPath) {
            ResourceService.getPresignedURL(props.resource.thumbnailPath).then((t) => {
                setThumbnailURL(t);
            })
        }
    }, []);

    const [videoProgress, setVideoProgress] = useState<VideoProgressInput | null>();

    /**
     * Load the video
     */
    useEffect(() => {
        if (videoProgress === undefined && props.resource.type === 'video') {
            loadVideoProgress();
            setLockStatus();
        }
    });
    const loadVideoProgress = async () => {
        try {
            const vp = await VideoService.getProgress(props.user.getUsername(), props.resource?.id || '');
            setVideoProgress(vp || null);
        } catch (err) {
            console.log('Err', err);
        }
    }

    const setLockStatus = async () => {
        try {
            const lockStatus = await VideoService.getLockStatus(props.user.getUsername(), props.resource?.id || '',props.resource.videoSequence||1);
            setLockState(lockStatus)
        } catch (err) {
            console.log('Err', err);
        }
    }

    console.log('videoProgress:::', videoProgress);
    console.log('classes.icon:::', classes.icon);

    let link = lockState ? '#' :`/${props.resource.type}/${props.resource.id}` 
    if(videoProgress?.location===1){
        link = '#';
    }
    // if (lockState){

    // }

    // let link:string =  props.searchResources?.nextToken == props.resource.id || videoProgress?.progress === 1 ? `/${props.resource.type}/${props.resource.id}` : '#'
    //let link:string =  props.resource?.title == "Part 2: Getting Started" ? `/${props.resource.type}/${props.resource.id}` : '#'
        //link =  props.resource?.title === "Part 1: Welcome" ? `/${props.resource.type}/${props.resource.id}` : '#'

    console.log('link::', link);


    // let link:string =  props.searchResources?.nextToken == props.resource.id || videoProgress?.progress === 1 ? `/${props.resource.type}/${props.resource.id}` : '#'


    return (
        <div className={classes.root}>
            <Typography gutterBottom noWrap variant="subtitle1" component="h2">
                <strong>{props.resource.title || "Untitled Resource"}</strong>
            </Typography>
            <Link to={link}>
                <div className={`${props.lockAspectRatio ? classes.playerWrapper : undefined} ${link === '#' ? classes.arrowCursor : ""}`} ref={link === '#' ? allOtherVideos : playNext}>
                    <div className={props.lockAspectRatio ? classes.thumbnail : undefined }>
                        {props.showOverlay && <div className={classes.playArrowContainer}> 
                            {lockState?  <Lock className={ classes.icon } /> : <PlayArrow className={classes.icon} /> }
                        </div>}
                        {videoProgress?.progress === 1 && <div className={classes.viewedIcon}><VisibilityIcon style={{ width: '100%', height: '100%' }} /></div>}
                        <img className={classes.image} src={thumbnailURL} />
                        {videoProgress?.progress && <div className={classes.progressBar} style={{ width: ((videoProgress?.progress || 0) * 100) + '%' }}></div>}
                    </div>
                    
                </div>
            </Link>
            <Grid container>
                <Grid item className={classes.descriptionItem}>
                    <Tooltip
                        placement="top"
                        disableHoverListener={!props.resource.description}
                        disableTouchListener={!props.resource.description}
                        disableFocusListener={!props.resource.description}
                        enterDelay={500}
                        enterTouchDelay={250}
                        title={
                            <React.Fragment>
                                <Typography variant="subtitle2" component="h2">
                                    {props.resource.description}
                                </Typography>
                            </React.Fragment>
                        }>
                        <Typography variant="subtitle2" component="h2" className={classes.description}>
                            {props.resource.description}
                        </Typography>
                    </Tooltip>
                </Grid>
                {/* <Grid item className={classes.iconItem}>
                    <IconButton className={classes.shareIcon} size="small" onClick={() => props.onShareLink(props.resource)}>
                        <ShareIcon fontSize="small" />
                    </IconButton>
                </Grid> */}
            </Grid>
        </div>
    );
}