import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ReportIcon from '@material-ui/icons/Report';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { RouteComponentProps } from 'react-router-dom';
import { Subject } from 'rxjs';
import { GetResourceQuery, UpdateResourceInput } from '../../../../API';
import ResourceService from '../../ResourceService';
import ResourcePage from '../Shared/ResourcePage';
import VideoPlayer from './VideoPlayer';
import VideoService from './VideoService';
import ReportIssueDialog from '../Shared/ReportIssueDialog';

const useStyles = makeStyles((theme) => ({
    downloadControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
}));

interface VideoPageRouteProps {
    id: string;
}

interface RouteProps extends RouteComponentProps<VideoPageRouteProps> { };
const mainSubject = new Subject();
export default function VideoPage(props: RouteProps) {

    const classes = useStyles();
    const [error, setError] = useState<string>('');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [seekComplete, setSeekComplete] = useState<boolean>(false);
    const [userPromise, setUserPromise] = useState<Promise<CognitoUser>>();

    /**
     * Load the user object
     */
    useEffect(() => {
        if (!userPromise) {
            setUserPromise(Auth.currentAuthenticatedUser());
        }
    });

    const [user, setUser] = useState<CognitoUser>();
    if (!user) {
        Auth.currentAuthenticatedUser().then((u) => {
            console.log('User', u);
            setUser(u);
        });
    }

    /**
     * Set the initial progress of the video
     * 
     * @param playerRef video player reference
     */
    const handleReady = async (playerRef: React.MutableRefObject<ReactPlayer | null>) => {
        const user = await userPromise;
        if (user) {
            const progress = await VideoService.getProgress(user.getUsername(), props.match.params.id);
            if (!progress) {
                await VideoService.saveProgress(user.getUsername(), props.match.params.id, 0, resource?.title || "", resource?.videoSequence || 1, resource?.totalMins || 0);
            }
            if (progress && !seekComplete) {
            console.log('Seeking to', progress?.location);
            playerRef.current?.seekTo(progress?.location || 0, 'fraction');
            setSeekComplete(true);
            }
        }
    }



    /**
     * Save the video progress
     * 
     * @param playerRef video player reference
     */
    const handleSaveState = async (playerRef: React.MutableRefObject<ReactPlayer | null>) => {
        const user = await userPromise;
        console.log('playerRef', playerRef, playerRef?.current?.getCurrentTime(), playerRef?.current?.getDuration());
        if (user && playerRef?.current?.getCurrentTime() && playerRef?.current?.getDuration()) {
            const location = playerRef?.current?.getCurrentTime() / playerRef?.current?.getDuration();
            console.log('Saving location', location);
            await VideoService.saveProgress(user.getUsername(), props.match.params.id, location, resource?.title || "", resource?.videoSequence || 1, resource?.totalMins || 0);
            if (location === 1) {
                mainSubject.next('VIDEO_COMPLETED');
            }
        }
    }

    /**
     * If user attempts to scrub they will be returned to the last save point
     * @param playerRef
     * @param currentSeconds 
     */
    const handleSeek = async (playerRef: React.MutableRefObject<ReactPlayer | null>, currentSeconds: number) => {
        if (seekComplete && user) {
            const lastProgress = await VideoService.getProgress(user.getUsername(), props.match.params.id);
            if (lastProgress?.progressInMins) {
                const lastProgressSeconds = lastProgress.progressInMins * 60;
                const delta = Math.abs(currentSeconds - lastProgressSeconds);
                // Anything more than a second
                if (delta > 1) {
                    playerRef.current?.seekTo(lastProgressSeconds);
                }
            }
            else if (lastProgress=== undefined ||lastProgress=== null )
            {
                playerRef.current?.seekTo(0);
            }
        }
        // } else {
        //     playerRef.current?.seekTo(0);
        // }
    }

    const handleVideoUpdate = async (resource: UpdateResourceInput) => {
        try {
            await ResourceService.updateResource(resource);
        } catch (err: any) {
            console.log('ERR', err.errors[0].message);
            setError(err.errors[0].message);
        }
    }

    const [resource, setResource] = useState<GetResourceQuery['getResource']>();
    const [presignedVideoURL, setPresignedVideoURL] = useState<string>();
    const handleResourceLoaded = (resource: GetResourceQuery['getResource']) => {
        setResource(resource);
        if (resource?.resourcePath) {
            ResourceService.getPresignedURL(resource.resourcePath).then((u) => {
                setPresignedVideoURL(u);
            })
        }
    }

    return (
        <ResourcePage user={user} id={props.match.params.id} onResourceUpdate={handleVideoUpdate} onResourceLoaded={handleResourceLoaded}>
            {error && <h5 style={{ color: 'red' }}>{error}</h5>}
            {presignedVideoURL && <VideoPlayer url={presignedVideoURL} onSaveState={handleSaveState} onReady={handleReady} onSeek={handleSeek} />}
            <div style={{ maxWidth: 800 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <div className={classes.downloadControl}>
                            {/* <IconButton
                                size="small"
                                color="secondary"
                                onClick={() => setDialogOpen(true)}>
                                <ReportIcon />
                            </IconButton> */}
                        </div>
                    </Grid>
                </Grid>
            </div>
            {resource && <ReportIssueDialog resource={resource} dialogOpen={dialogOpen} />}
        </ResourcePage>
    );
}