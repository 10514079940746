import { Button, debounce, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import Icon from '@material-ui/core/Icon';
import InputLabel from '@material-ui/core/InputLabel';
import Pagination from '@material-ui/lab/Pagination';
import { isNull } from "lodash";
import { relative } from "path";
import QRCode from 'qrcode.react';
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { UpdateResourceInput } from "../../API";
import AppFilter from "../AppFilter/AppFilter";
import SearchBox from "../SearchBox/SearchBox";
import ResourceThumbnail from "./Parts/ResourceThumbnail";
import ResourceGridProps from "./ResourceGridProps";
import ResourceService from "./ResourceService";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    qrCode: {
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    gridItem: {
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        },
    },
   /* filterContainer: {
        [theme.breakpoints.down('xs')]: {
            width: '80%'
        },
       
    }, */
    
    selectLabel: {
       height: 40,
        verticalAlign: 'middle',

    },
    centerGrid:{
        paddingLeft: '60px'
    },
    startMessage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '600px',
        marginBottom: '50px',
        fontSize: '20px',
    }
  
}));

export default function ResourceGrid(props: ResourceGridProps) {
    const classes = useStyles();

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [columns, setColumns] = useState<number>(0);
    const [searchText, setSearchText] = useState<string>('');
    const [appType, setAppType] = useState<string>('all');
    const [emailAddress, setEmailAddress] = useState<string>('');
    const [pages, setPages] = useState<Map<number, string>>(new Map());
    const [pageCount, setPageCount] = useState<number>(1);
    const [resources, setResources] = useState<UpdateResourceInput[]>();
    const [open, setOpen] = useState(false);
    const [dialogResource, setDialogResource] = useState<UpdateResourceInput>();
    const [searchResourcesList, setSearchResourcesList] = useState<any>();

    /**
    * Get the number of columns in the grid
    */
    const targetRef = useRef<any>();
    const getPageSize = () => columns * 4;
    const updatePageSize = () => {
        if (targetRef.current) {
            setColumns(Math.floor(targetRef.current.offsetWidth / 275));
        }
    }
    useLayoutEffect(() => {
        updatePageSize();
    }, []);
    let movement_timer: NodeJS.Timeout | null = null;
    const RESET_TIMEOUT = 500;
    window.addEventListener('resize', () => {
        if (movement_timer) {
            clearInterval(movement_timer);
        }
        movement_timer = setTimeout(updatePageSize, RESET_TIMEOUT);
    });

    useEffect(() => {
        console.log('Number of columns changed');
        handlePageRefresh(null, currentPage, appType, searchText);
    }, [columns]);

    const handlePageRefresh = async (event: React.ChangeEvent<unknown> | null, page: number, appType?: string, searchText?: string) => {
        setCurrentPage(page);
        if (searchText !== undefined) {
            setSearchText(searchText);
        }
        console.log(appType);
        console.log(searchText);
        const pageToken = pages.get(page);
        const pageSize = getPageSize();
        if (pageSize) {
            const searchResourcesQuery = (appType == "all") ? await ResourceService.getAllResources(props.resourceType, searchText, pageSize, pageToken):
            await ResourceService.searchResources(props.resourceType, appType, searchText, pageSize, pageToken);
            
            const searchResources = searchResourcesQuery.data?.searchResources;
            console.log('searchResources:::', searchResources);

            setSearchResourcesList(searchResources)

            if (searchResources?.nextToken) {
                console.log('searchResources?.nextToken:::', searchResources?.nextToken);
                setPages(new Map(pages.set(page + 1, searchResources?.nextToken)));
            }else{
                console.log('!!!searchResources.items:::', searchResources?.items);
            }
            
            if (searchResources?.nextToken) {
                setPages(new Map(pages.set(page + 1, searchResources?.nextToken)));
            }
            setPageCount(Math.ceil((searchResources?.total || 1) / getPageSize()));
            setResources(searchResources?.items as UpdateResourceInput[]);
            window.scrollTo(0, 0);
            console.log('pages', pages);
        }   
    }

    const handleSearch = debounce((searchText: string) => { 
        setPages(new Map());
        handlePageRefresh(null, 1, appType, searchText);
    }, 750);

    const handleAppFilter = (appType: string) => {
        setPages(new Map());
        setAppType(appType);
        handlePageRefresh(null, 1, appType, undefined);
    } 

    const displayShowLinkDialog = (resource: UpdateResourceInput) => {
        setDialogResource(resource);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmailAddress(e.target.value);
    }

    useEffect(() => {
        handlePageRefresh(null, 1, "0"); 
    }, []);

    return (
        <React.Fragment>
        
        <Grid container spacing={2}>
        <Grid container item spacing={1} sm={4}>
        {/* <Grid item xs={6} sm={5}>
       
        <InputLabel id="video-filter-label" className={classes.selectLabel}> {props.resourceType === 'video' ? 'Filter Videos by Application' : 'Filter Documents by Application'}</InputLabel>

        </Grid> */}
        {/* <Grid item xs={6} sm={7}>
        <AppFilter onFilter={handleAppFilter}></AppFilter>

        </Grid> */}
        </Grid>

        {/* <Grid container item xs={12} sm={8}>
        <SearchBox onSearch={handleSearch} />
            
        </Grid> */}
        
        
        </Grid>
        
            <br />
            <Grid container ref={targetRef} 
                className={classes.centerGrid}
                alignItems="center">
                {resources?.map((resource) => (
                    <Grid item key={resource.id} className={classes.gridItem}>
                        <ResourceThumbnail
                            searchResources={searchResourcesList}
                            user={props.user}
                            resource={resource}
                            showOverlay={props.resourceType === 'video'}
                            lockAspectRatio={props.resourceType === 'video'}
                            onShareLink={displayShowLinkDialog} />
                    </Grid>
                ))}
            </Grid>
            <Grid
                container
                justify="center"
                alignItems="center">
                <Grid item>
                    <Pagination onChange={handlePageRefresh} count={pageCount} shape="rounded" />
                </Grid>
            </Grid>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">Share {dialogResource?.title}</DialogTitle>
                <DialogContent>
                    {dialogResource?.description &&
                        <Typography variant="subtitle2"><em>Description:</em> {dialogResource?.description}</Typography>}
                    <DialogContentText id="alert-dialog-description">
                        <QRCode size={200} className={classes.qrCode} value={window.location.href + "/" + dialogResource?.id} />
                        <div>
                            <TextField fullWidth label="Email address" value={emailAddress} onChange={handleEmailChange} />
                        </div>
                        <br />
                        <Button
                            disabled={emailAddress.length < 1}
                            href={`mailto:${emailAddress}?subject=${encodeURIComponent('Training Hub - ' + dialogResource?.title) || ''}&body=${encodeURIComponent('Link: ' + window.location.href + "/" + dialogResource?.id) || ''}`}
                            variant="contained"
                            color="primary"
                            endIcon={<Icon />}>
                            Email Link
                        </Button>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}