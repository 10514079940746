import { Grid, IconButton, makeStyles, TextField, Typography, FormControl } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { GetResourceQuery, UpdateResourceInput } from '../../../../API';
import ResourceService from '../../ResourceService';
import ResourcePageProps from './ResourcePageProps';
import SideBarService from '../../../SideBar/SideBarService';
import SideBarData from '../../../SideBar/SideBarData';
import TagContainer from '../../../Upload/TagContainer';
import { NavigateBeforeSharp, TagFacesSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    appDataContainer: {
        alignItems: 'flex-end',
        justifyContent: 'left',
        marginTop: 20,
        width: '100%',
        display: 'inline',

    },
    /* gridItem: {
    [theme.breakpoints.down('xs')]: { matches screen withds less than and including
        width: '100%'
    },
    },*/
    reportIssue: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },

    formLabel: {
        paddingRight: 10,
        fontSize: '1rem',
        color: '#143b59',
        fontWeight: 'bold',

    },
    
    startMessage: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '600px',
        marginBottom: '50px',
        fontSize: '20px',
    },

    metaRightColumn: {
        paddingLeft: 70,
    },

    appDropdown: {
        [theme.breakpoints.up('xs')]: {
            width: 120
        },
        backgroundColor: 'white',
        //height: 23,
        //width: 120,
    },
}));

export default function ResourcePage(props: PropsWithChildren<ResourcePageProps>) {

    const classes = useStyles();

    const [resource, setResource] = useState<GetResourceQuery['getResource']>();

    const [titleEditMode, setTitleEditMode] = useState<boolean>(false);
    const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (resource) {
            setResource({ ...resource, title: e.target.value });
        }
    }

    const [descriptionEditMode, setDescriptionEditMode] = useState<boolean>(false);
    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (resource) {
            setResource({ ...resource, description: e.target.value });
        }
    }

    const handleAppChange = (e: React.ChangeEvent<{ value: unknown }>) => {
        if (resource) {
            setResource({ ...resource, appAsString: e.target.value as string });
            setApp(e.target.value as string);
        }
    }

    const [tags, setTags] = useState<string[]>();


    const handleTagsChange = (tags: string[]) => {
        setTags(tags);
        if (resource) {
            setResource({ ...resource, tags: tags });
        }

    }

    const [appMenuItems, setAppMenuItems] = React.useState<SideBarData[]>();
    const [app, setApp] = useState<string>('');
    const [error, setError] = useState<string>();
    const handleEdit = async () => {
        try {
            const appMainMenuItemsQuery = await SideBarService.getAllApplications();
            const appMainMenuItems = appMainMenuItemsQuery.data?.listApplications;
            setAppMenuItems(appMainMenuItems?.items as SideBarData[]);
            console.log(appMenuItems);
        } catch (err) {

            const message = "Error loading data";
            setError(`${message}: ${err}`);
            console.log(message, err);
        }
        if (resource?.appAsString) {
            setApp(resource?.appAsString);
        }
    };


    const [groups, setGroups] = useState<string[]>();
    useEffect(() => {
        if (!groups) {
            setGroups(props.user?.getSignInUserSession()?.getAccessToken().payload['cognito:groups']);
        }
    });

    useEffect(() => {
        if (props.id) {
            ResourceService.getResource(props.id).then(resourceQuery => {
                if (resourceQuery.data) {
                    setResource(resourceQuery.data.getResource);
                    props.onResourceLoaded(resourceQuery.data.getResource);
                } else {
                    // TODO: implementƒ
                    // setError()
                }
            });
        }
    }, []);

    return (
        <div >
            <div>
            <div className={classes.startMessage}>
            Before you begin, you will need to complete your paid training.
            <br /> Let’s get started!
            </div>
                <Grid container justifyContent="center">
                    <Grid container justifyContent="center">
                        {!titleEditMode ?
                            <Typography variant="h6" component="h2" gutterBottom>
                                <strong>{resource?.title || ""}</strong>
                            </Typography>
                            :
                            <TextField value={resource?.title} onChange={handleTitleChange} />}
                    </Grid>
                    {groups && groups.indexOf('Admin') >= 0 && <Grid item>
                        {!titleEditMode ?
                            <IconButton size="small" onClick={() => setTitleEditMode(true)}>
                                <EditIcon color="primary" />
                            </IconButton>
                            :
                            <IconButton size="small" onClick={() => {
                                setTitleEditMode(false);
                                if (resource) {
                                    props.onResourceUpdate(resource);
                                }
                            }}>
                                <SaveIcon color="primary" />
                            </IconButton>
                        }
                    </Grid>}
                </Grid>
                <Grid container justifyContent="center">
                    {props.children}
                </Grid>
                {/* <Grid container>
                    <Grid item xs={12}>
                        Last Updated: {resource?.updatedAt && (new Date(resource?.updatedAt)).toLocaleDateString()} {resource?.updatedAt && (new Date(resource?.updatedAt)).toLocaleTimeString()}
                    </Grid>
                </Grid > */}
                <Grid container className={classes.appDataContainer} spacing={4} justifyContent="center">
                    {/* <Grid item xs={10}> */}

                            {!descriptionEditMode ?
                                <Typography variant="subtitle2" component="h2" className={classes.metaRightColumn}>
                                    {resource?.description}
                                </Typography>
                                :
                                <TextField value={resource?.description} onChange={handleDescriptionChange} className={classes.metaRightColumn} />}


                    {/* </Grid> */}

                    {/* <Grid container spacing={2}>
                        <Grid item xs={6} sm={1}><InputLabel id="application-label" className={classes.formLabel}>Application:</InputLabel></Grid>
                        <Grid item xs={6} sm={3}  >
                            {!descriptionEditMode ?
                                <Typography variant="subtitle2" component="h2" className={classes.metaRightColumn}>
                                    {resource?.appAsString}
                                </Typography>
                                :

                                <div>

                                    <FormControl>

                                        <Select labelId="application-label"
                                            id="app-label" variant="outlined"
                                            onChange={handleAppChange} value={app} className={classes.appDropdown && classes.metaRightColumn}
                                        >

                                            <MenuItem value="">None</MenuItem>
                                            {appMenuItems &&
                                                appMenuItems.map((appMenuItem, appMenuItemIndex) => (
                                                    <MenuItem value={appMenuItem.appName}>{appMenuItem.appName}</MenuItem>
                                                ))}
                                        </Select>

                                    </FormControl></div>
                            }  </Grid>
                    </Grid> */}
                    {/* <Grid container spacing={2}>
                        <Grid item xs={6} sm={1}><InputLabel id="tags-label" className={classes.formLabel}>Tags:</InputLabel></Grid>

                        <Grid item xs={6} sm={3} >
                            {!descriptionEditMode ?
                                <Typography variant="subtitle2" component="h2" className={classes.metaRightColumn}>
                                    {resource?.tags &&
                                        resource?.tags.map((resource, resourceIndex) => (
                                            <span>{resource},&nbsp;</span>
                                        ))}
                                </Typography>
                                :
                                <TagContainer onChange={handleTagsChange} edit={true} existingTags={resource?.tags} className={classes.metaRightColumn} />

                            }
                        </Grid>


                        {groups && groups.indexOf('Admin') >= 0 && <Grid item>
                            {!descriptionEditMode ?
                                <IconButton size="small" onClick={() => { setDescriptionEditMode(true); handleEdit(); }}>
                                    <EditIcon color="primary" />
                                </IconButton>
                                :

                                <IconButton size="small" onClick={() => {
                                    setDescriptionEditMode(false);
                                    if (resource) {
                                        props.onResourceUpdate(resource);
                                    }
                                }}>
                                    <SaveIcon color="primary" />
                                </IconButton>
                            }
                        </Grid>}
                    </Grid>*/}
                </Grid> 

            </div>
        </div >
    );
}