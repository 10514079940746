import { API, graphqlOperation } from "aws-amplify";
import { omit } from 'lodash';
import { CreateUserInfoInput, UpdateUserInfoInput } from "../../../../API";
import * as mutations from '../../../../graphql/mutations';
import ResourceService from "../../ResourceService";

export default class VideoService {

    private constructor() {

    }

    static async getNumberOfWatchedVideos(userId: string): Promise<number> {
        const videos = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.videos || [];
        return videos.filter(v => v.progress === 1).length;
    }

    static async getAllResources(): Promise<number> {
        console.log('in getAllResources:::::::');
        //@TODO use graphpl aggregate count
        const videos = await (await ResourceService.getAllResources('video', '') || []); //props.resourceType = , searchText, pageSize, pageToken
        console.log('getAllResources, videos:::::::', videos);
        console.log('getAllResources, videos.data.searchResources.items:::::::', videos.data?.searchResources?.items.length);
        console.log('getAllResources, videos?:::::::', videos);



        return videos.data?.searchResources?.items.length || 0
    }

    static async getProgress(userId: string, videoId: string) {
        const videos = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.videos || [];
        return videos.find(v => v.videoId === videoId);
    }

    static async getLockStatus(userId: string, videoId: string, videoSequence: number) {
        const videos = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.videos || [];
        let maxViewed = 1.0
        videos.forEach((video) => {
            // 1. enable the next 
            if (video.progress > 0.95) {
                maxViewed = (maxViewed <= (video.videoSequence || 0) ? (video.videoSequence  || 0) +1 : maxViewed)
            }
        });
        if (maxViewed >= videoSequence) {
            return false
        }
        else {
            return true
        }
    }

    static async getLockData(userId: string, videoId: string) {
        const videos = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo?.videos || [];
        return videos.find(v => v.videoId === videoId);
    }

    static async saveProgress(userId: string, videoId: string, played: number,name: string,seq: number,totalMins: number) {
        let userInfo = await (await ResourceService.getUserInfo(userId)).data?.getUserInfo;
        console.log("START")
        console.log(seq)
        console.log(totalMins)
        console.log(name)
        console.log("END")
        if (!userInfo) {
            const createdUserInfo = await (await ResourceService.createUserInfo({ id: userId, videos: [] })).data?.createUserInfo;
            if (createdUserInfo) {
                userInfo = { ...createdUserInfo };
            } else {
                const errMessage = 'Error creating user info';
                console.log(errMessage, createdUserInfo);
                throw new Error(errMessage);
            }
        }
        const videos = userInfo.videos || [];

        console.log('Searching for video id', videoId);
        let videoIndex = videos?.findIndex(v => v.videoId === videoId);
        if (videoIndex >= 0) {
            console.log('Found id', videoId);
            videos[videoIndex].location = played;
            if (!videos[videoIndex].progress || videos[videoIndex].progress < played) {
                videos[videoIndex].progress = played;
            }
            videos[videoIndex].name = name
            videos[videoIndex].videoSequence = seq
            videos[videoIndex].progressInMins = totalMins*played
            



        } else {
            console.log('No id found', videoId);
            const video = 
            {   videoId, 
                location: played, 
                progress: played,
                name: name,
                videoSequence: seq,
                progressInMins: totalMins*played
             };
            userInfo.videos?.push(video as any);
        }
        console.log('Saving progress', userInfo);
        return await API.graphql(graphqlOperation(mutations.updateUserInfo, { input: VideoService.getSafeUserInfoCopy(userInfo) }));
    }

    /**
     * Get a copy of a resource input object with only the expected attributes
     * 
     * @param resource resource to copy
     */
    private static getSafeUserInfoCopy(resource: UpdateUserInfoInput | CreateUserInfoInput) {
        // Get a copy with only the expected attributes (exclude createdAt, updatedAt, etc.)
        return omit(resource, ['createdAt', 'updatedAt']);
    }

}