import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ReportIcon from '@material-ui/icons/Report';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { RouteComponentProps } from 'react-router-dom';
import { GetResourceQuery, UpdateResourceInput } from '../../../../API';
import ResourceService from '../../ResourceService';
import ReportIssueDialog from '../Shared/ReportIssueDialog';
import ResourcePage from '../Shared/ResourcePage';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => ({
    page: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'aliceblue'
    },
    controls: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    downloadControl: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
}));

interface DocumentPageRouteProps {
    id: string;
}

interface RouteProps extends RouteComponentProps<DocumentPageRouteProps> { };

const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
};

export default function DocumentsPage(props: RouteProps) {

    const classes = useStyles();

    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const handleDocumentUpdate = (resource: UpdateResourceInput) => {
        ResourceService.updateResource(resource);
    }

    const [resource, setResource] = useState<GetResourceQuery['getResource']>();
    const [presignedURL, setPresignedURL] = useState<string>();
    const handleResourceLoaded = (resource: GetResourceQuery['getResource']) => {
        setResource(resource);
        if (resource?.resourcePath) {
            ResourceService.getPresignedURL(resource.resourcePath).then((u) => {
                setPresignedURL(u);
            })
        }
    }

    const handleLoadSuccess = ({ numPages }: { numPages: number }) => {
        setNumPages(numPages);
    }

    const changePage = (offset: number) => {
        setPageNumber(pageNumber + offset);
    }

    const previousPage = () => changePage(-1);

    const nextPage = () => changePage(1);

    const [user, setUser] = useState<CognitoUser>();
    if (!user) {
        Auth.currentAuthenticatedUser().then((u) => {
            console.log('User', u);
            setUser(u);
        });
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item alignItems="center">
                    <ResourcePage user={user} id={props.match.params.id} onResourceUpdate={handleDocumentUpdate} onResourceLoaded={handleResourceLoaded}>
                        {presignedURL &&
                            <Document
                                file={presignedURL}
                                onLoadSuccess={handleLoadSuccess}
                                options={options}>
                                <Page pageNumber={pageNumber} renderAnnotationLayer={false} />
                            </Document>}
                        <div className={classes.page}>
                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </div>
                        <Grid container>
                            <Grid item xs={5}>

                            </Grid>
                            <Grid item xs={2}>
                                <div className={classes.controls}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}>
                                        <ArrowBackIosIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}>
                                        <ArrowForwardIosIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                            <Grid item xs={5}>
                                <div className={classes.downloadControl}>
                                    <IconButton
                                        size="small"
                                        color="primary"
                                        onClick={() => window.open(presignedURL, "_blank")}>
                                        <CloudDownloadIcon />
                                    </IconButton>
                                    <IconButton
                                        size="small"
                                        color="secondary"
                                        onClick={() => setDialogOpen(true)}>
                                        <ReportIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Grid>
                    </ResourcePage>
                </Grid>
            </Grid>
            {resource && <ReportIssueDialog resource={resource} dialogOpen={dialogOpen} />}
        </React.Fragment>
    );
}