import { AppBar, Badge, Hidden, IconButton, makeStyles, Toolbar, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useEffect, useState } from 'react';
import VideoService from '../ResourceGrid/Parts/Video/VideoService';
import HeaderProps from './HeaderProps';
import logo from './Resources/logo-horiz.png';
import { useHistory } from 'react-router-dom';
import { Auth } from "aws-amplify";

export default function Header(props: HeaderProps) {
    
    let history = useHistory();
    const redirect = () => {
        history.push('/')
      }
    const useStyles = makeStyles((theme) => ({
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            //.MuiPaper-elevation4-362
            boxShadow: 'none'
        },
        toolbar: {
            color: theme.palette.text.primary,
            backgroundColor: 'white',
            ...theme.mixins.toolbar
        },
        logo: {
            width: 265,
            height: 'auto',
            marginRight: 25
        },
        sectionDesktop: {
            display: 'none',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
                flex: 1,
                position: 'absolute',
                right: 25
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        signOutButton: {
            // width: "150px",
            width: "70px",
            height: "30px",

            padding: "1px 1px",
            margin: "1px 0",
            display: "inline-block",
            border: "1px solid #ccc",
            borderRadius: "1px",
            boxSizing: "border-box",
            cursor: "pointer",
            float: "right",
            fontSize: "14px",
          }
    }));

    const classes = useStyles();

    const [email, setEmail] = useState<string>();
    useEffect(() => {
        if (props.user) {
            props.user.getUserAttributes((err, result) => {
                setEmail(result?.find(a => a.getName() === 'email')?.getValue());
            });
        }
    });

    /**
     * The number of videos the user has completed
     */
    const [numberOfWatchedVideos, setNumberOfWatchedVideos] = useState<number>();
    useEffect(() => {
        if (props.user) {
            VideoService.getNumberOfWatchedVideos(props.user.getUsername()).then((nw) => setNumberOfWatchedVideos(nw));
        }
    });

    return (
        <React.Fragment>
            <AppBar id="app-bar" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {/* displays the hamberger menu */}
                    {/* <Hidden smUp>
                        <IconButton onClick={props.onMenuClick} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <MenuIcon />
                        </IconButton>
                    </Hidden> */}
                    <img src={logo} className={classes.logo} onClick={redirect} />
                    <Hidden smDown>
                        <Typography variant="h4" component="h2">
                            Orientation
                        </Typography>
                    </Hidden>
                    <div className={classes.sectionDesktop}>
                        <Typography variant="subtitle1" component="h2" style={{ padding: 10 }}>
                            {email}
                        </Typography>
                        <IconButton
                            edge="end"
                            color="inherit">
                            <Badge badgeContent={numberOfWatchedVideos} color="error" />
                        </IconButton>
                        <button
                          className={classes.signOutButton}
                          onClick={() => {
                            Auth.signOut();
                          }}
                        >
                          Sign out
                        </button>
                    </div>
                </Toolbar>
            </AppBar>
            <Toolbar id="back-to-top-anchor" />
        </React.Fragment>
    );
}